/*!--------------------------------------------------------------
# main.scss
#
# Sass file for Areen template.
# Author: SaltTechno
#
# This is compressed CSS file. You get uncompressed version of
# this file and all source scss files with download.
--------------------------------------------------------------*/



/*--------------------------------------------------------------
# [TABLE OF CONTENTS]
#
# 1. VARIABLES & MIXINS
# 2. COMMON - MARGIN & PADDING
# 3. SLIDERS
# 4. SECTIONS & TITLES
# 5. FEATURES
# 6. NAVBAR
# 7. LISTS
# 8. COUNTERS
# 9. BUTTONS
# 10. STEPS
# 11. TIMELINE
# 12. PRICING
# 13. OWL CAROUSEL
# 14. BLOG
# 15. PAGE LOADERS
# 16. FOOTER
# 17. PAGE HEADERS
# 18. SHORTCODES
# 19. TABS
# 20. TEAM
# 21. BLOG
# 22. CONTACT & MAP
--------------------------------------------------------------*/



/*--------------------------------------------------------------
# 1. VARIABLES & MIXINS
--------------------------------------------------------------*/

// Google Font
@import 'https://fonts.googleapis.com/css?family=Poppins:600|Questrial';

// Variables
$st-body-font: 'Questrial', sans-serif;
$st-heading-font: 'Poppins', sans-serif;
$primary-color: #e74c3c;
$white-color: #fff;
$light-gray-color: #f6f6f6;
$light-text-color: #c2c2c2;
$black-color: #323232;
$light-black-color: #686868;
$dark-gray: darken($light-gray-color, 10%);
$dark-primary: darken($primary-color, 20%);
$lighter-black: lighten($black-color, 10%);

// Bootstrap Variables
$brand-primary: $primary-color;
$border-radius-base: 6px;
$border-radius-large: 0px;
$border-radius-small: 0px;

// Hover variables
$activeColor: $primary-color;

// Owl variables
$color-base: $primary-color;
$color-gray: $light-gray-color;
$nav-color-hover: $primary-color;
$nav-font-size: 1em;
$nav-rounded: $border-radius-base;

// Import bourbon & typographic
@import "bourbon/bourbon";
@import "typographic/typographic";

// Typographic settings
$body-font: $st-body-font;
$body-font-weight: 400;
$body-color: $black-color;
$header-font: $st-heading-font;
$header-font-weight: 600;
$header-color: $black-color;
$min-font: 12px;
$max-font: 16px;


// Fire up the mixin
@include typographic;

/*--------------------------------------------------------------
# 2. COMMON
--------------------------------------------------------------*/
body{
    font-family: $body-font;
    -webkit-font-smoothing: antialiased;
    background-color: $white-color;
    color: $light-black-color;
    text-rendering: optimizeLegibility !important;
    letter-spacing: 0.5px;
}
@include selection {
    background-color: $black-color;
    color: $white-color;
}
ul{
    list-style: none;
}
a {
    outline: none !important;
    text-decoration: none !important;
    color: $light-black-color;
    @include transition(all 0.4s ease-in-out);
}
a:hover {
    text-decoration: none;
    color: $primary-color !important;
}
p a{
    color: $primary-color;
}
.light-text{
    color: $light-text-color;
}
.primary-color {
    color: $primary-color;
}
.zero-padding{
    padding: 0px !important;
}
.mbn {
    margin-bottom: 0 !important;
}
.mb10 {
    margin-bottom: 10px !important;
}
.mb20 {
    margin-bottom: 20px !important;
}
.mb30 {
    margin-bottom: 30px !important;
}
.mb40 {
    margin-bottom: 40px !important;
}
.mb50 {
    margin-bottom: 50px !important;
}
.mb60 {
    margin-bottom: 60px !important;
}
.mtn {
    margin-top: 0 !important;
}
.mt10 {
    margin-top: 10px !important;
}
.mt20 {
    margin-top: 20px !important;
}
.mt30 {
    margin-top: 30px !important;
}
.mt40 {
    margin-top: 40px !important;
}
.mt50 {
    margin-top: 50px !important;
}
.mt60 {
    margin-top: 60px !important;
}
.mln {
    margin-left: 0;
}
.ml10 {
    margin-left: 10px;
}
.ml20 {
    margin-left: 20px;
}
.ml30 {
    margin-left: 30px;
}
.ml40 {
    margin-left: 40px;
}
.ml50 {
    margin-left: 50px;
}
.ml60 {
    margin-left: 60px;
}
.mrn {
    margin-right: 0;
}
.mr10 {
    margin-right: 10px;
}
.mr20 {
    margin-right: 20px;
}
.mr30 {
    margin-right: 30px;
}
.mr40 {
    margin-right: 40px;
}
.mr50 {
    margin-right: 50px;
}
.mr60 {
    margin-right: 60px;
}
.pbn {
    padding-bottom: 0 !important;
}
.pb10 {
    padding-bottom: 10px;
}
.pb20 {
    padding-bottom: 20px;
}
.pb30 {
    padding-bottom: 30px;
}
.pb40 {
    padding-bottom: 40px;
}
.pb50 {
    padding-bottom: 50px;
}
.pb60 {
    padding-bottom: 60px;
}
.pb70 {
    padding-bottom: 70px;
}
.pb80 {
    padding-bottom: 80px;
}
.ptn {
    padding-top: 0 !important;
}
.pt10 {
    padding-top: 10px;
}
.pt20 {
    padding-top: 20px;
}
.pt30 {
    padding-top: 30px;
}
.pt40 {
    padding-top: 40px;
}
.pt50 {
    padding-top: 50px;
}
.pt60 {
    padding-top: 60px;
}
.pt70 {
    padding-top: 70px;
}
.pt80 {
    padding-top: 80px;
}
.pt90 {
    padding-top: 90px;
}
.pln {
    padding-left: 0;
}
.pl10 {
    padding-left: 10px;
}
.pl20 {
    padding-left: 20px;
}
.pl30 {
    padding-left: 30px;
}
.pl40 {
    padding-left: 40px;
}
.pl50 {
    padding-left: 50px;
}
.pl60 {
    padding-left: 60px;
}
.prn {
    padding-right: 0;
}
.pr10 {
    padding-right: 10px;
}
.pr20 {
    padding-right: 20px;
}
.pr30 {
    padding-right: 30px;
}
.pr40 {
    padding-right: 40px;
}
.pr50 {
    padding-right: 50px;
}
.pr60 {
    padding-right: 60px;
}
.mtm-80{
    margin-top: -80px;
}
.st-img {
    /* border-radius: $border-radius-base; */
}
.breadcrumb {
    font-size: 90%;
}
.white-color{
    color: $white-color !important;
}
.white-bg {
    background-color: $white-color !important;
}
.gray-bg {
    background-color: $light-gray-color !important;
}



/*--------------------------------------------------------------
# 3. SLIDERS
--------------------------------------------------------------*/
.NotGeneric-Title,
.tp-caption.NotGeneric-Title {
	color: rgba(255,255,255,1.00);
	font-size: 70px;
	line-height: 70px;
	font-weight: $header-font-weight;
	font-style: normal;
	font-family: $st-heading-font;
	padding: 10px 0;
	text-decoration: none;
	text-align: left;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0 0 0 0;
}
.NotGeneric-SubTitle,
.tp-caption.NotGeneric-SubTitle {
	color: rgba(255,255,255,1.00);
	font-size: 13px;
	line-height: 20px;
	font-weight: $header-font-weight;
	font-style: normal;
	font-family: $st-heading-font;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0 0 0 0;
	text-align: left;
	letter-spacing: 4px;
	text-align: left;
}
.NotGeneric-Icon,
.tp-caption.NotGeneric-Icon {
	color: rgba(255,255,255,1.00);
	font-size: 30px;
	line-height: 30px;
	font-weight: $header-font-weight;
	font-style: normal;
	font-family: $st-heading-font;
	padding: 0;
	text-decoration: none;
	background-color: rgba(0,0,0,0);
	border-color: rgba(255,255,255,0);
	border-style: solid;
	border-width: 0;
	border-radius: 0 0 0 0;
	text-align: left;
	letter-spacing: 3px;
	text-align: left;
}
.NotGeneric-Button,
.tp-caption.NotGeneric-Button {
	color: rgba(255,255,255,1.00);
	font-size: 14px;
	line-height: 14px;
	font-weight: $header-font-weight;
	font-style: normal;
	font-family: $st-heading-font;
	padding: 10px 30px;
	text-decoration: none;
	background-color: rgba(0,0,0,0);
	border-color: rgba(255,255,255,0.50);
	border-style: solid;
	border-width: 1px;
	border-radius: 0 0 0 0;
	text-align: left;
	letter-spacing: 3px;
	text-align: left;
}
.NotGeneric-Button:hover,
.tp-caption.NotGeneric-Button:hover {
	color: rgba(255,255,255,1.00);
	text-decoration: none;
	background-color: transparent;
	border-color: rgba(255,255,255,1.00);
	border-style: solid;
	border-width: 1px;
	border-radius: 0 0 0 0;
	cursor: pointer;
}
.NotGeneric-BigButton,
.tp-caption.NotGeneric-BigButton {
	color: rgba(255,255,255,1.00);
	font-size: 14px;
	line-height: 14px;
	font-weight: $header-font-weight;
	font-style: normal;
	font-family: $st-heading-font;
	padding: 27px 30px;
	text-decoration: none;
	background-color: rgba(0,0,0,0);
	border-color: rgba(255,255,255,0.15);
	border-style: solid;
	border-width: 1px;
	border-radius: 0 0 0 0;
	text-align: left;
	letter-spacing: 3px;
}
.NotGeneric-BigButton:hover,
.tp-caption.NotGeneric-BigButton:hover {
	color: rgba(255,255,255,1.00);
	text-decoration: none;
	background-color: rgba(0,0,0,0);
	border-color: rgba(255,255,255,1.00);
	border-style: solid;
	border-width: 1px;
	border-radius: 0 0 0 0;
	cursor: pointer;
}
.RotatingWords-TitleWhite,
.tp-caption.RotatingWords-TitleWhite {
	color: rgba(255,255,255,1.00);
	font-size: 70px;
	line-height: 70px;
	font-weight: $header-font-weight;
	font-style: normal;
	font-family: $st-heading-font;
	padding: 0;
	text-decoration: none;
	text-align: left;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0 0 0 0;
}
.RotatingWords-Button,
.tp-caption.RotatingWords-Button {
	color: rgba(255,255,255,1.00);
	font-size: 20px;
	line-height: 20px;
	font-weight: $header-font-weight;
	font-style: normal;
	font-family: $st-heading-font;
	padding: 20px 50px;
	text-decoration: none;
	text-align: left;
	background-color: rgba(0,0,0,0);
	border-color: rgba(255,255,255,0.15);
	border-style: solid;
	border-width: 2px;
	border-radius: 0 0 0 0;
	letter-spacing: 3px;
}
.RotatingWords-Button:hover,
.tp-caption.RotatingWords-Button:hover {
	color: rgba(255,255,255,1.00);
	text-decoration: none;
	background-color: transparent;
	border-color: rgba(255,255,255,1.00);
	border-style: solid;
	border-width: 2px;
	border-radius: 0 0 0 0;
	cursor: pointer;
}
.RotatingWords-SmallText,
.tp-caption.RotatingWords-SmallText {
	color: rgba(255,255,255,1.00);
	font-size: 14px;
	line-height: 20px;
	font-weight: $header-font-weight;
	font-style: normal;
	font-family: $st-heading-font;
	padding: 0;
	text-decoration: none;
	text-align: left;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0 0 0 0;
	text-shadow: none;
}
.FullSiteBlock-SubTitle,
.tp-caption.FullSiteBlock-SubTitle {
	color: rgba(51,51,51,1.00);
	font-size: 25px;
	line-height: 34px;
	font-weight: $header-font-weight;
	font-style: normal;
	font-family: $st-heading-font;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0 0 0 0;
}
.FullSiteBlock-FooterLink,
.tp-caption.FullSiteBlock-FooterLink {
	color: rgba(85,85,85,1.00);
	font-size: 15px;
	line-height: 20px;
	font-weight: $body-font-weight !important;
	font-style: normal;
	font-family: $st-body-font;
	padding: 0;
	text-decoration: none;
	text-align: left;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0 0 0 0;
}
.FullSiteBlock-FooterLink:hover,
.tp-caption.FullSiteBlock-FooterLink:hover {
	color: rgba(0,150,255,1.00);
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0 0 0 0;
	cursor: pointer;
}
.fb-share-button.fb_iframe_widget iframe {
	width: 115px!important;
}
#tp-socialwrapper {
	opacity: 0;
}
.FullSiteBlock-SubTitle,
.tp-caption.FullSiteBlock-SubTitle {
	color: rgba(51,51,51,1.00);
	font-size: 25px;
	line-height: 34px;
	font-weight: $body-font-weight;
	font-style: normal;
	font-family: $st-body-font;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0 0 0 0;
}
.FullSiteBlock-Title,
.tp-caption.FullSiteBlock-Title {
    font-family: $st-heading-font;
    font-weight: $header-font-weight;
}
.CreativeFrontPage-Btn,
.tp-caption.CreativeFrontPage-Btn {
	color: rgba(255,255,255,1.00);
	font-size: 14px;
	line-height: 60px;
	font-style: normal;
    font-family: $st-heading-font;
    font-weight: $header-font-weight;
	padding: 0 50px;
	text-decoration: none;
	text-align: left;
	background-color: rgba(0,104,92,1.00);
	border-color: rgba(0,0,0,1.00);
	border-style: solid;
	border-width: 0;
	border-radius: $border-radius-base;
	letter-spacing: 2px;
}
.CreativeFrontPage-Btn:hover,
.tp-caption.CreativeFrontPage-Btn:hover {
	color: rgba(255,255,255,1.00);
	text-decoration: none;
	background-color: rgba(0,0,0,0.25);
	border-color: rgba(0,0,0,1.00);
	border-style: solid;
	border-width: 0;
	border-radius: $border-radius-base;
	cursor: pointer;
}
.tp-flip-index {
    z-index:1000 !important
}
.hermes.tp-bullets {}
.hermes .tp-bullet {
	overflow: hidden;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	background-color: rgba(0,0,0,0);
	box-shadow: inset 0 0 0 2px rgb(255,255,255);
	@include transition(all 0.4s ease-in-out);
	position: absolute;
}
.hermes .tp-bullet:hover {
	background-color: rgba(0,0,0,0.21);
}
.hermes .tp-bullet:after {
	content: ' ';
	position: absolute;
	bottom: 0;
	height: 0;
	left: 0;
	width: 100%;
	background-color: rgb(255,255,255);
	box-shadow: 0 0 1px rgb(255,255,255);
	@include transition(all 0.4s ease-in-out);
}
.hermes .tp-bullet.selected:after {
	height: 100%;
}



/*--------------------------------------------------------------
# 4. SECTIONS & TITLES
--------------------------------------------------------------*/
.section-title {
    margin-bottom: 60px;
    .small-title {
        color: $primary-color;
        margin-top: 0;
    }
    .main-title {
        margin-top: 0;
    }
    .sub-title {
        font-size: 1em;
    }
    &.page-title {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
section.content-section {
    padding: 100px 0px;
    background: $white-color;
    &.black-section {
        background-color: $black-color;
        color: $white-color;
        h1, h2, h3, h4, h5, h6 {
            color: $white-color;
        }
        .small-title {
            color: $primary-color;
        }
    }
    &.gray-section {
        background: $light-gray-color;
    }
}
.parallax-section {
    .primary-grad {
        @include background(linear-gradient(to right, rgba($primary-color, 0.8), rgba($dark-primary, 0.6)));
        color: $white-color;
        h1, h2, h3, h4, h5, h6 {
            color: $white-color;
        }
    }
    .black-grad {
        background: $black-color;
        background: rgba($black-color, 0.6);
        color: $white-color;
        h1, h2, h3, h4, h5, h6 {
            color: $white-color;
        }
    }
}
.black-half {
    background: $black-color;
    color: $white-color;
    padding-left: 2.618em;
    padding-right: 2.618em;
    h1, h2, h3, h4, h5, h6 {
        color: $white-color;
    }
}




/*--------------------------------------------------------------
# 5. FEATURES
--------------------------------------------------------------*/
.feature-box {
    background: $light-gray-color;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    padding: 2.618em 1em;
    border-radius: $border-radius-base;
    position: relative;
    overflow: hidden;
    @include transition(all 0.4s ease-in-out);
    h4 {
        margin-top: 0;
        @include transition(all 0.4s ease-in-out);
    }
    p {
        margin-bottom: 0;
    }
    .icon {
        display: block;
        width: 85px;
        height: 85px;
        font-size: 50px;
        line-height: 85px;
        margin: auto;
        border-radius: 50%;
        @include transition(all 0.4s ease-in-out);
        margin-bottom: 10px;
    }
    .bg-icon {
        position: absolute;
        color: darken($light-gray-color, 35%);
        @include transition(all 0.4s ease-in-out);
        bottom: -20px;
        right: -20px;
        font-size: 150px;
        opacity: 0.1;
    }
    &:hover {
        background: $black-color;
        color: $white-color;
        .icon {
            color: $primary-color;
            background-color: $white-color;
        }
        .bg-icon {
            color: $light-gray-color;
        }
        h4 {
            color: $white-color;
        }
    }
}
.feature-box-2 {
    .box-left {
        float: left;
        width: 15%;
        .icon {
            font-size: 52px;
            color: $primary-color;
        }
    }
    .box-right {
        margin-bottom: 1.618em;
        width: 85%;
        float: left;
        h3, h4, h5 {
            margin-top: 0;
        }
    }
}
.feature-box-3 {
    background: $white-color;
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.15);
    padding: 2.618em 1em;
    border-radius: $border-radius-base;
    position: relative;
    overflow: hidden;
    @include transition(all 0.4s ease-in-out);
    h4, h5 {
        margin-top: 0;
        @include transition(all 0.4s ease-in-out);
    }
    p {
        margin-bottom: 0;
    }
    .icon {
        font-size: 50px;
        line-height: 85px;
        margin: auto;
        @include transition(all 0.4s ease-in-out);
        margin-bottom: 10px;
        color: $primary-color;
    }
    &:hover {
        background: $black-color;
        color: $white-color;
        .icon {
            color: $primary-color;
        }
        .bg-icon {
            color: $light-gray-color;
        }
        h4, h5 {
            color: $white-color;
        }
    }
}
.feature-box-4 {
    .box-left {
        float: left;
        width: 15%;
        .icon {
            font-size: 40px;
            color: $primary-color;
        }
    }
    .box-right {
        margin-bottom: 1.618em;
        width: 85%;
        float: left;
        h3, h4, h5 {
            margin-top: 0;
        }
    }
}
@media (max-width: 500px) {
    .feature-box-2 .box-left .icon {
        font-size: 42px;
    }
}



/*--------------------------------------------------------------
# 6. NAVBAR
--------------------------------------------------------------*/
@media (min-width: 992px) {
    ul.nav li.dropdown:hover > ul.dropdown-menu {
        display: block;
    }
}
.navbar-nav{
    > li > a{
        color: $white-color;
        text-transform: uppercase;
        letter-spacing: 1px;
        line-height: 30px;
        @include transition(all 0.4s ease-in-out);
    }
}
.navbar-fixed-top{
    border: 0px;
}
.navbar{
    margin-bottom: 0;
    @include transition(all 0.4s ease-in-out);
    a {
        font-family: $st-heading-font;
        letter-spacing: 1px;
        font-size: 90%;
    }
}
.navbar-header {
    position: relative;
    z-index: 2;
}
.navbar-brand{
    height: 60px;
    img{
        height: 25px;
    }
    .dark-logo{
        display: none;
    }
}
.nav > li > a:hover, .nav > li > a:focus{
    background: $primary-color;
    color: $white-color !important;
}
.nav > li:hover, .nav > li:focus{
    a {
        background: $primary-color;
        color: $white-color !important;
    }
}
.me-menu .me-menu-content{
    padding: 0px;
}
.me-menu .nav, .me-menu .collapse, .me-menu .dropup, .me-menu .dropdown{
    position: relative;
}
.nav-btn{
    margin-top: 10px;
    display: inline-block;
}
.dropdown-menu{
    background: $primary-color;
    border: 0px;
    padding: 0;
    color: $white-color;
    min-width: 240px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    a{
        color: $white-color;
        display: block;
        padding: 3px 0;
        position: relative;
        @include transition(all 0.4s ease-in-out);
        &:hover{
            color: $white-color !important;
            padding-left: 6px;
        }
        &.btn{
            padding: 0;
        }
    }
    .has-submenu {
        position: relative;
        &:hover {
            .me-submenu {
                display: block;
            }
        }
    }
    .me-submenu {
        position: absolute;
        left: 220px;
        padding: 20px;
        padding-left: 20px !important;
        top: 0;
        min-width: 240px;
        background: $primary-color;
        display: none;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    }
    .glyphicon {
        font-size: 80%;
        &.glyphicon-chevron-right {
            float: right;
            line-height: inherit;
        }
    }
    .me-menu-content{
        display: table;
    }
    .nav-col{
        display: table-cell;
        min-width: 240px;
        padding-top: 20px;
        padding-bottom: 20px;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6{
            margin-top: 0;
            margin-left: 0;
            font-weight: 400;
            color: $white-color;
            padding-left: 20px;
        }
        ul{
            padding-left: 20px;
            padding-right: 10px;
        }
        &.dark-bg{
            background: $black-color;
        }
    }
}
.dd-left{
    .dropdown-menu{
        right: 0;
        left: auto;
    }
}
.body-scrolled{
    .dark-logo{
        display: inline-block;
    }
    .light-logo{
        display: none;
    }
    .navbar{
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
        background: $white-color;
        .navbar-nav{
            > li > a{
                color: $black-color;
            }
        }
        .nav > li > a:hover, .nav > li > a:focus{
            background: $primary-color;
            color: $white-color;
        }
    }
}
.dark-menu {
    .dark-logo{
        display: inline-block;
    }
    .light-logo{
        display: none;
    }
    .navbar{
        .navbar-nav{
            > li > a{
                color: $black-color;
            }
        }
        .nav > li > a:hover, .nav > li > a:focus{
            background: $primary-color;
            color: $white-color;
        }
    }
}
.navbar-toggle {
    background-color: $primary-color;
    .icon-bar {
        background-color: $white-color;
    }
}
.dropdown-menu .nav-col ul {
    padding-right: 0;
    li > a {
        padding-right: 10px;
    }
}
@media (max-width: 991px) {
    .navbar-nav {
        float: none !important;
    }
    .navbar-collapse.collapse.in {
        background: $black-color;
    }
    .nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
        background: $primary-color;
    }
    .nav>li:focus a, .nav>li:hover a, .nav>li>a:focus, .nav>li>a:hover {
        background: transparent;
    }
    .dropdown-menu .nav-col ul {
        padding-right: 0;
    }
    .body-scrolled .navbar .navbar-nav>li>a {
        color: $white-color;
    }
    .dark-menu {
        .navbar{
            .navbar-nav{
                > li > a{
                    color: $white-color;
                }
            }
        }
    }

}
.navbar {
    .nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
        background-color: $primary-color;
    }
}




/*--------------------------------------------------------------
# 7. LISTS
--------------------------------------------------------------*/
.ul-style-3 {
    line-height: 34px;
    li {
        margin-bottom: 10px;
    }
    .icon {
        background: $primary-color;
        color: $white-color;
        width: 34px;
        height: 34px;
        line-height: 34px;
        font-size: 22px;
        text-align: center;
        border-radius: 50%;
        display: inline-block;
        margin-right: 6px;
    }
}



/*--------------------------------------------------------------
# 8. COUNTERS
--------------------------------------------------------------*/
.counter-box-2 {
    background-color: $light-gray-color;
    color: $black-color;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    text-align: center;
    padding: 30px;
    border-radius: $border-radius-base;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    @include transition(all 0.4s ease-in-out);
    p {
        margin-bottom: 0;
        position: relative;
        z-index: 1;
    }
    .icon {
        display: block;
        font-size: 40px;
        color: $primary-color;
        @include transition(all 0.4s ease-in-out);
    }
    .counter {
        font-size: 2.618em;
        margin: 15px;
        display: block;
    }
    .bg-icon {
        position: absolute;
        right: -20px;
        bottom: -20px;
        font-size: 120px;
        color: $black-color;
        opacity: 0.75;
        z-index: 0;
    }
    &:hover{
        background: transparent;
        color: $white-color;
        .icon {
            color: $white-color;
        }
    }
}
.counter-box-3 {
    background-color: rgba(255,255,255,0.05);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    text-align: center;
    padding: 30px;
    border-radius: $border-radius-base;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    @include transition(all 0.4s ease-in-out);
    p {
        margin-bottom: 0;
        position: relative;
        z-index: 1;
    }
    .icon {
        display: block;
        font-size: 40px;
        @include transition(all 0.4s ease-in-out);
    }
    .counter {
        font-size: 2.618em;
        margin: 15px;
        display: block;
    }
    .bg-icon {
        position: absolute;
        right: -20px;
        bottom: -20px;
        font-size: 120px;
        color: $black-color;
        opacity: 0.75;
        z-index: 0;
    }
    &:hover{
        background: $light-gray-color;
        color: $black-color;
        .icon {
            color: $primary-color;
        }
    }
}



/*--------------------------------------------------------------
# 9. BUTTONS
--------------------------------------------------------------*/
.st-btn {
    letter-spacing: 1px;
    font-size: 1em;
    line-height: 2;
    text-transform: capitalize;
    padding: 0.618em 2.618em;
    background: $white-color;
    color: $primary-color;
    border: 0;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    &.btn-rounded {
        border-radius: 30px;
        &[class*="hvr-"]:before {
            border-radius: 30px;
        }
    }
    &:hover {
        color: $white-color !important;
        background: $primary-color;
    }
    &.btn-primary {
        background: $primary-color;
        color: $white-color;
        &:hover {
            color: $primary-color !important;
            background: $white-color;
        }
    }
    &.small-btn {
        font-size: 80%;
    }
}



/*--------------------------------------------------------------
# 10. STEPS
--------------------------------------------------------------*/
.steps {
    counter-reset: st-counter;
    .step-box {
        position: relative;
        &:before {
            content: counter(st-counter);
            counter-increment: st-counter;
            position: absolute;
            font-family: $st-heading-font;
            color: darken($light-gray-color, 5%);
            font-size: 4.236em;
            font-style: italic;
            z-index: 0;
            width: 90%;
            top: 40px;
            left: 0px;
            text-align: right;
            padding-right: 20px;
        }
    }
}



/*--------------------------------------------------------------
# 11. TIMELINE
--------------------------------------------------------------*/
.timeline {
	position: relative;
	.tm-icon {
		width: 70px;
		height: 70px;
		text-align: center;
		background-color: $light-gray-color;
		border-radius: 50%;
		position: absolute;
		span {
			color: $primary-color;
			font-size: 38px;
			line-height: 70px;
		}
	}
	.tm-detail {
		padding-left: 90px;
		margin-bottom: 50px;
		color: $light-black-color;
		font-size: 1em;
	}
}
.timeline::before {
	content: '';
	position: absolute;
	top: 0;
	left: 30px;
	height: 100%;
	width: 2px;
	background: $light-gray-color;
}
.black-section, .black-half {
    .timeline {
    	.tm-icon {
    		background-color: $white-color;
    	}
    	.tm-detail {
    		color: $light-gray-color;
    	}
    }
    .timeline::before {
    	background: $white-color;
    }
}



/*--------------------------------------------------------------
# 12. PRICING
--------------------------------------------------------------*/
.pricing-box {
    background: $white-color;
    border-radius: $border-radius-base;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    .pricing-head {
        background-color: $light-gray-color;
        border-radius: $border-radius-base $border-radius-base 0 0;
        text-align: center;
        border-top: 3px solid $primary-color;
        padding: 1.618em;
        position: relative;
        overflow: hidden;
        h1, h2, h3, h4, h5, h6 {
            margin: 0;
        }
        .striked {
            text-decoration: line-through;
        }
        .bg-icon {
            position: absolute;
            bottom: -20px;
            right: -20px;
            font-size: 120px;
            color: darken($light-gray-color, 35%);
            opacity: 0.2;
        }
    }
    .pricing-body {
        padding: 1em;
        ul {
            li {
                line-height: 2.25;
                .icon {
                    float: right;
                }
            }
        }
    }
    .pricing-foot {
        a {
            display: block;
            padding: 1em;
            background: $light-gray-color;
            color: $black-color;
            text-align: center;
            text-transform: capitalize;
            letter-spacing: 1px;
            border-radius: 0 0 $border-radius-base $border-radius-base;
            @include transition(all 0.4s ease-in-out);
            &:hover {
                background: $primary-color;
                color: $white-color !important;
            }
        }
    }
    &.popular {
        .pricing-head {
            background: $black-color;
            color: $white-color;
            h1, h2, h3, h4, h5, h6 {
                color: $white-color;
            }
        }
    }
}



/*--------------------------------------------------------------
# 13. OWL CAROUSEL
--------------------------------------------------------------*/
.owl-carousel {
    cursor: ew-resize;
}



/*--------------------------------------------------------------
# 14. BLOG
--------------------------------------------------------------*/
.blogs-grid {
    [class*="col-"] {
        padding: 0;
    }
}
.latest-post {
    position: relative;
    .blog-info {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 1em;
        overflow: hidden;
        color: $white-color;
        @include background(linear-gradient(to right bottom, rgba($dark-primary, 0.9), rgba($primary-color, 0.5)));
        @include transition(all 0.4s ease-in-out);
        h1, h2, h3, h4, h5, h6, a {
            color: $white-color !important;
            margin: 0;
        }
        .blog-meta {
            font-size: 90%;
        }
        .blog-excerpt {
            margin-bottom: 0;
        }
    }
    &:hover {
        .blog-info {
            opacity: 1;
        }
    }
}



/*--------------------------------------------------------------
# 15. PAGE LOADERS
--------------------------------------------------------------*/
.page-loader {
	position: fixed;
	z-index: 10001;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: $light-gray-color;
	img.logo-loader {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}
}
.spinner {
	width: 40px;
	height: 40px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	background-color: $primary-color;
	border-radius: 100%;
	-webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
	animation: sk-scaleout 1.0s infinite ease-in-out;
}
@-webkit-keyframes sk-scaleout {
	0% {
		-webkit-transform: scale(0);
	}
	100% {
		-webkit-transform: scale(1.0);
		opacity: 0;
	}
}
@keyframes sk-scaleout {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	100% {
		-webkit-transform: scale(1.0);
		transform: scale(1.0);
		opacity: 0;
	}
}
/* spinner-2 */
.spinner-2 {
	width: 40px;
	height: 40px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	background-color: $primary-color;
	-webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
	animation: sk-rotateplane 1.2s infinite ease-in-out;
}
@-webkit-keyframes sk-rotateplane {
	0% {
		-webkit-transform: perspective(120px);
	}
	50% {
		-webkit-transform: perspective(120px) rotateY(180deg);
	}
	100% {
		-webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
	}
}
@keyframes sk-rotateplane {
	0% {
		transform: perspective(120px) rotateX(0deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
	}
	50% {
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
	}
	100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	}
}
/* spinner-3 */
.spinner-3 {
	width: 40px;
	height: 40px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}
.double-bounce1,
.double-bounce2 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: $primary-color;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: sk-bounce 2.0s infinite ease-in-out;
	animation: sk-bounce 2.0s infinite ease-in-out;
}
.double-bounce2 {
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
}
@-webkit-keyframes sk-bounce {
	0%,
	100% {
		-webkit-transform: scale(0.0);
	}
	50% {
		-webkit-transform: scale(1.0);
	}
}
@keyframes sk-bounce {
	0%,
	100% {
		transform: scale(0.0);
		-webkit-transform: scale(0.0);
	}
	50% {
		transform: scale(1.0);
		-webkit-transform: scale(1.0);
	}
}
/* spinner-4 */
.spinner-4 {
	margin: auto;
	width: 50px;
	height: 40px;
	text-align: center;
	font-size: 10px;
}
.spinner-4 > div {
	background-color: #333;
	height: 100%;
	width: 6px;
	display: inline-block;
	-webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
	animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.spinner-4 .rect2 {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}
.spinner-4 .rect3 {
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
}
.spinner-4 .rect4 {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}
.spinner-4 .rect5 {
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}
@-webkit-keyframes sk-stretchdelay {
	0%,
	100%,
	40% {
		-webkit-transform: scaleY(0.4);
	}
	20% {
		-webkit-transform: scaleY(1.0);
	}
}
@keyframes sk-stretchdelay {
	0%,
	100%,
	40% {
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
	}
	20% {
		transform: scaleY(1.0);
		-webkit-transform: scaleY(1.0);
	}
}
/* spinner-5 */
.spinner-5 {
	margin: auto;
	width: 40px;
	height: 40px;
	position: relative;
}
.cube1,
.cube2 {
	background-color: #333;
	width: 15px;
	height: 15px;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
	animation: sk-cubemove 1.8s infinite ease-in-out;
}
.cube2 {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}
@-webkit-keyframes sk-cubemove {
	25% {
		-webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
	}
	50% {
		-webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
	}
	75% {
		-webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
	}
	100% {
		-webkit-transform: rotate(-360deg);
	}
}
@keyframes sk-cubemove {
	25% {
		transform: translateX(42px) rotate(-90deg) scale(0.5);
		-webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
	}
	50% {
		transform: translateX(42px) translateY(42px) rotate(-179deg);
		-webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
	}
	50.1% {
		transform: translateX(42px) translateY(42px) rotate(-180deg);
		-webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
	}
	75% {
		transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
		-webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
	}
	100% {
		transform: rotate(-360deg);
		-webkit-transform: rotate(-360deg);
	}
}
/* spinner-6 */
.spinner-6 {
	margin: 100px auto 0;
	width: 70px;
	text-align: center;
}
.spinner-6 > div {
	width: 18px;
	height: 18px;
	background-color: #333;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.spinner-6 .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}
.spinner-6 .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
	0%,
	100%,
	80% {
		-webkit-transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1.0);
	}
}
@keyframes sk-bouncedelay {
	0%,
	100%,
	80% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1.0);
		transform: scale(1.0);
	}
}
.border-0 {
	border-radius: 0 !important;
}



/*--------------------------------------------------------------
# 16. FOOTER
--------------------------------------------------------------*/
.footer {
    background: #222222; /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #222222 , #434343); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #222222 , #434343); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: $light-gray-color;
    h5, h6 {
        color: $light-gray-color;
        text-transform: uppercase;
    }
    p {
        margin-bottom: 0;
        i {
            display: inline-block;
            width: 25px;
        }
    }
    .top-container {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .footer-bottom {
        padding: 20px 0;
        background: #222;
        font-size: 90%;
        color: $light-gray-color;
        a {
            padding: 0 15px;
        }
    }
    .social-link {
        padding: 10px;
        font-size: 1.618em;
    }
}


/*--------------------------------------------------------------
# 17. PAGE HEADER
--------------------------------------------------------------*/
.page-header {
    background: $light-gray-color;
    padding: 150px 0;
    margin-top: 0;
    &.pattern {
        background-image: url(../images/pattern.png);
        background-repeat: repeat;
    }
    .breadcrumb {
        background: transparent;
    }
}



/*--------------------------------------------------------------
# 18. SHORTCODES
--------------------------------------------------------------*/
.acc-default {
	.panel-title {
		font-size: 1em;
		text-transform: capitalize;
	}
	.panel-title a {
		display: block;
	}
}
.acc-wo-background {
	.panel-title {
		font-size: 1em;
		text-transform: capitalize;
	}
	.panel-title a {
		display: block;
	}
	.panel-heading {
		background-color: transparent;
	}
}
.acc-wo-gap {
	.panel-title {
		font-size: 1em;
		text-transform: capitalize;
	}
	.panel-title a {
		display: block;
	}
	&.panel-group .panel {
		margin-top: -1px;
	}
}
.acc-wo-border {
	.panel-title {
		font-size: 1em;
		text-transform: capitalize;
	}
	.panel-title a {
		display: block;
	}
	.panel {
		border: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}
.acc-faq-1 {
	.panel-title {
		font-size: 1em;
		text-transform: capitalize;
		.icon {
			transform: rotate(135deg);
			-ms-transform: rotate(135deg);
			-webkit-transform: rotate(135deg);
            @include transition(all 0.4s ease-in-out);
		}
		.collapsed .icon {
			transform: rotate(0deg);
			-ms-transform: rotate(0deg);
			-webkit-transform: rotate(0deg);
		}
	}
	.panel-body {
		padding-left: 40px;
	}
	.panel-title a {
		display: block;
	}
	.panel {
		border: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.panel-heading {
		background: transparent;
		border: 0;
	}
	.panel-body {
		border: 0 !important;
	}
}
.acc-faq-2 {
	.panel-title {
		font-size: 1em;
		text-transform: capitalize;
		.icon {
			transform: rotate(135deg);
			-ms-transform: rotate(135deg);
			-webkit-transform: rotate(135deg);
			float: right;
			color: $white-color;
            padding: 10px 0px;
            @include transition(all 0.4s ease-in-out);
		}
		.collapsed .icon {
			transform: rotate(0deg);
			-ms-transform: rotate(0deg);
			-webkit-transform: rotate(0deg);
		}
	}
	.panel-body {
		padding-left: 20px;
	}
	.panel-title a {
		display: block;
	}
	.panel {
		border: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.panel-heading {
		background: $black-color;
		color: $white-color;
		border: 0;
		@include transition(all 0.4s ease-in-out);
		&:hover {
			background: $black-color;
		}
	}
	.panel-body {
		border: 0 !important;
	}
}
.nav-tabs > li > a {
	border-radius: 0;
}
.nav-tabs > li > a .icon {
	font-size: 16px;
}
blockquote {
    padding: 10px 20px;
    small {
        padding-top: 10px;
    }
}
.blockquote-default {
    border-left: 5px solid $dark-gray;
    &.primary-color {
        border-left-color: $primary-color;
    }
}
.blockquote-reverse {
    border-left: 0;
    border-right: 5px solid $dark-gray;
    &.primary-color {
        border-right-color: $primary-color;
    }
}
.blockquote-2 {
    border-left: 0;
    position: relative;
}
.blockquote-2::before {
    color: rgba(0,0,0,0.1);
    content: "“";
    display: block;
    font-size: 140px;
    left: 5px;
    position: absolute;
    top: 15px;
}
.blockquote-reverse-2 {
    border-left: 0;
    text-align: right;
}
.blockquote-reverse-2::before {
    color: rgba(0,0,0,0.1);
    content: "”";
    display: block;
    font-size: 140px;
    right: 5px;
    position: absolute;
    top: 15px;
}
.gray-bg {
    .blockquote-default {
        border-left: 5px solid darken($dark-gray, 10%);
    }
    .blockquote-reverse {
        border-right: 5px solid darken($dark-gray, 10%);
        border-left: 0;
    }
}
.bt-grid [class*=col-] {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #d9d9d9;
    border: 1px solid #ccc;
}




/*--------------------------------------------------------------
# 19. TABS
--------------------------------------------------------------*/
.tabs-section {
	.nav.nav-pills {
		display: table;
		width: 100%;
		a i {
			display: block;
			width: 50px;
			height: 50px;
			font-size: 42px;
			line-height: 50px;
			margin: auto;
		}
	}
	.nav-pills > li {
		display: table-cell;
		float: none;
	}
	.nav-pills > li > a {
		color: $light-black-color;
	}
	.nav-pills > li.active > a,
	.nav-pills > li.active > a:focus,
	.nav-pills > li.active > a:hover {
		background-color: transparent !important;
		color: $primary-color !important;
	}
	.tab-pane {
		padding: 40px 0 30px;
	}
}
.tab-style-2 {
    .nav-tabs {
        border-bottom: 0;
    }
    .nav-tabs>li>a {
        padding: 10px 25px;
        &:hover {
            background: none;
            border-color: transparent;
        }
    }
    .nav-tabs>li.active>a,
    .nav-tabs>li.active>a:focus,
    .nav-tabs>li.active>a:hover {
        color: $black-color;
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid darken($light-gray-color, 30%);
    }
    .tab-content {
        padding: 20px;
    }
}
.tab-style-1 {
    .nav-tabs {
        border-bottom: 1px solid $dark-gray;
        margin: 0;
        padding: 0;
        border-bottom-color: $dark-gray;
        li {
            display: inline-block;
            float: none;
        }
    }
    .nav-tabs>li>a {
        background: darken($light-gray-color, 10%);
        border-bottom: none;
        border-left: 1px solid $dark-gray;
        border-right: 1px solid $dark-gray;
        border-top: 3px solid $lighter-black;
    }
    .nav-tabs>li.active>a,
    .nav-tabs>li.active>a:focus,
    .nav-tabs>li.active>a:hover {
        background: #fff;
        border-left-color: $dark-gray;
        border-right-color: $dark-gray;
        border-top: 3px solid $primary-color;
        border-bottom-color: transparent;
    }
    .tab-content {
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
        background-color: #fff;
        border: 1px solid $dark-gray;
        border-top: 0;
        padding: 15px;
    }
}
.tab-style-3 {
    .nav-tabs {
        border-bottom: 0;
        margin: 0;
        padding: 0;
        display: table;
        width: 100%;
        li {
            display: table-cell;
            text-align: center;
            float: none;
        }
    }
    .nav-tabs>li>a {
        background: darken($light-gray-color, 10%);
        border-bottom: none;
        border-left: 1px solid $dark-gray;
        border-right: 1px solid $dark-gray;
        border-top: 3px solid $lighter-black;
        margin-right: 0;
        color: $lighter-black;
    }
    .nav-tabs>li.active>a,
    .nav-tabs>li.active>a:focus,
    .nav-tabs>li.active>a:hover {
        background: #fff;
        border-left-color: $dark-gray;
        border-right-color: $dark-gray;
        border-top: 3px solid $primary-color;
        border-bottom: 1px solid #FFF;
        color: $black-color;
    }
    .tab-content {
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
        background-color: #fff;
        border: 1px solid $dark-gray;
        border-top: 0;
        padding: 15px;
    }
}
.tab-style-4 {
    .nav-tabs {
        border-top: 0;
        margin: 0;
        padding: 0;
        display: table;
        width: 100%;
        li {
            display: table-cell;
            text-align: center;
            float: none;
        }
    }
    .nav-tabs>li>a {
        background: darken($light-gray-color, 10%);
        border-top: none;
        border-left: 1px solid $dark-gray;
        border-right: 1px solid $dark-gray;
        border-bottom: 3px solid $lighter-black;
        margin-right: 0;
        color: $lighter-black;
    }
    .nav-tabs>li.active>a,
    .nav-tabs>li.active>a:focus,
    .nav-tabs>li.active>a:hover {
        background: #fff;
        border-left-color: $dark-gray;
        border-right-color: $dark-gray;
        border-bottom: 3px solid $primary-color;
        border-top: 1px solid #FFF;
        color: $black-color;
    }
    .tab-content {
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
        background-color: #fff;
        border: 1px solid $dark-gray;
        border-bottom: 0;
        padding: 15px;
    }
}
.tab-style-5 {
    .nav-tabs {
        border-bottom: 0;
        text-align: center;
    }
    .nav-tabs>li {
        float: none;
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
    }
    .nav-tabs>li>a {
        padding: 10px 25px;
        color: darken($light-gray-color, 30%);
        &:hover {
            background: none;
            border-color: transparent;
        }
    }
    .nav-tabs>li.active>a,
    .nav-tabs>li.active>a:focus,
    .nav-tabs>li.active>a:hover {
        color: $black-color;
        background-color: transparent;
        border: 1px solid $light-black-color;
        border-radius: 30px;
    }
    .tab-content {
        padding: 30px 20px 20px;
        text-align: center;
        line-height: 26px;
    }
}
.tab-style-6 {
    .nav-tabs {
        border-right: 1px solid $dark-gray;
        margin: 0;
        padding: 0;
        border-right-color: $dark-gray;
    }
    .nav-tabs>li>a {
        background: darken($light-gray-color, 10%);
        border-right: none;
        border-top: 1px solid $dark-gray;
        border-bottom: 1px solid $dark-gray;
        border-left: 3px solid $lighter-black;
    }
    .nav-tabs>li.active>a,
    .nav-tabs>li.active>a:focus,
    .nav-tabs>li.active>a:hover {
        background: #fff;
        border-top-color: $dark-gray;
        border-bottom-color: $dark-gray;
        border-left: 3px solid $primary-color;
        border-right-color: transparent;
    }
    .tab-content {
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
        background-color: #fff;
        border: 1px solid $dark-gray;
        // border-left: 0;
        padding: 15px;
    }
    .nav-content-div {
        z-index: 1;
    }
    .nav-tab-div {
        z-index: 2;
        margin-right: -1px;
    }
}
.tab-style-7 {
    .nav-tabs {
        border-left: 1px solid $dark-gray;
        margin: 0;
        padding: 0;
        border-left-color: $dark-gray;
    }
    .nav-tabs>li>a {
        background: darken($light-gray-color, 10%);
        border-left: none;
        border-top: 1px solid $dark-gray;
        border-bottom: 1px solid $dark-gray;
        border-right: 3px solid $lighter-black;
    }
    .nav-tabs>li.active>a,
    .nav-tabs>li.active>a:focus,
    .nav-tabs>li.active>a:hover {
        background: #fff;
        border-top-color: $dark-gray;
        border-bottom-color: $dark-gray;
        border-right: 3px solid $primary-color;
        border-left-color: transparent;
    }
    .tab-content {
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
        background-color: #fff;
        border: 1px solid $dark-gray;
        // border-left: 0;
        padding: 15px;
    }
    .nav-content-div {
        z-index: 1;
    }
    .nav-tab-div {
        z-index: 2;
        margin-left: -1px;
    }
}
.tab-style-8 {
    .nav-tabs {
        border-bottom: 0;
        text-align: center;
    }
    .nav-tabs>li {
        float: none;
        display: inline-block;
        margin-left: 0;
        margin-right: 0;
    }
    .nav-tabs>li>a {
        padding: 10px 25px;
        color: $lighter-black;
        border-bottom: 3px solid $dark-gray;
        &:hover {
            background: none;
            border-color: transparent;
        }
    }
    .nav-tabs>li.active>a,
    .nav-tabs>li.active>a:focus,
    .nav-tabs>li.active>a:hover {
        color: $primary-color;
        background-color: transparent;
        border: 0;
        border-bottom: 3px solid $primary-color;
    }
    .tab-content {
        padding: 30px 20px 20px;
        text-align: center;
        line-height: 26px;
    }
}
.tab-style-9 {
    .nav-tabs {
        border-bottom: 0;
        text-align: center;
    }
    .nav-tabs>li {
        float: none;
        display: inline-block;
        margin-left: 0;
        margin-right: 0;
    }
    .nav-tabs>li>a {
        padding: 10px 25px;
        color: lighten($light-black-color, 30%);
        border-bottom: 3px solid lighten($light-black-color, 30%);
        .icon {
            display: block;
            width: 80px;
            height: 80px;
            border: 2px dotted lighten($light-black-color, 30%);
            margin: auto;
            margin-bottom: 10px;
            line-height: 80px;
            font-size: 44px;
            border-radius: 50%;
        }
        &:hover {
            background: none;
            border-color: transparent;
        }
    }
    .nav-tabs>li.active>a,
    .nav-tabs>li.active>a:focus,
    .nav-tabs>li.active>a:hover {
        color: $primary-color;
        background-color: transparent;
        border: 0;
        border-bottom: 3px solid $primary-color;
        .icon {
            border-color: $primary-color;
        }
    }
    .tab-content {
        padding: 30px 20px 20px;
        text-align: center;
        line-height: 26px;
    }
}
.stat-box {
    padding: 15px;
    border: 1px solid $dark-gray;
    margin-left: -1px;
    margin-top: -1px;
    span.icon {
        font-size: 42px;
        display: block;
    }
    h5 {
        font-size: 18px;
        display: inline-block;
    }
    h6 {
        font-size: 12px;
        display: inline-block;
    }
    &:hover {
        background: $primary-color;
        color: $white-color;
        h5 {
            color: $white-color;
        }
        h6{
            color: $white-color;
        }
    }
}
.tab-style-10 {
    .nav-tabs {
        border-right: 0px;
        margin: 0;
        padding: 0;
    }
    .nav-tabs>li>a {
        border-top: 1px solid $dark-gray;
        border-bottom: 1px solid $dark-gray;
        border-right: 1px solid $dark-gray;
        border-left: 3px solid $lighter-black;
        color: $light-black-color;
    }
    .nav-tabs>li.active>a,
    .nav-tabs>li.active>a:focus,
    .nav-tabs>li.active>a:hover {
        border-top-color: $dark-gray;
        border-bottom-color: $dark-gray;
        border-left: 3px solid $primary-color;
        border-right-color: $dark-gray;
        color: $primary-color;
    }
    .tab-content {
        border: 0px;
        padding: 15px 25px;
    }
    .nav-content-div {
        z-index: 1;
    }
    .nav-tab-div {
        z-index: 2;
    }
}
.nav-tabs>li:focus a, .nav-tabs>li:hover a, .nav-tabs>li>a:focus, .nav-tabs>li>a:hover {
    color: $primary-color !important;
}



/*--------------------------------------------------------------
# 20. TEAM
--------------------------------------------------------------*/
.team-name {
    margin-top: 10px;
    margin-bottom: 0px;
    text-align: center;
    font-size: 1em;
}
.team-post {
    margin-top: 0;
    color: $primary-color;
    margin-bottom: 15px;
    text-align: center;
    line-height: 1;
    font-size: 0.9em;
}
.team-member {
    p {
        text-align: center;
        font-size: 0.85em;
        line-height: 1.5em;
    }
}



/*--------------------------------------------------------------
# 21. BLOG
--------------------------------------------------------------*/
.entry {
    margin-bottom: 50px;
    .entry-title {
        h4 {
            margin-top: 5px;
        }
    }
}


/**
 * ----------------------------------------------------------------------------------------
 * 22. CONTACT & MAP
 * ----------------------------------------------------------------------------------------
 */
#fullWidthMap{
    height: 400px;
}
.contact-info{
    p{
        color: $light-black-color;
    }
}
#{$all-text-inputs} {
  border: 1px solid darken($light-gray-color, 5%);
  background: $white-color;
  padding: 0.618em 1em;
  box-shadow: none;
  min-height: 40px;
  width: 100%;
}
#{$all-text-inputs-focus},
#{$all-text-inputs-hover} {
  border: 1px solid darken($light-gray-color, 15%);
  outline: 0;
  box-shadow: none;
}
#{$all-text-inputs-active} {
  border: 1px solid darken($light-gray-color, 15%);
  outline: 0;
  box-shadow: none;
}
.inp-rounded{
    border-radius: 30px;
}
