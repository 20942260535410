@charset "UTF-8";
/*!--------------------------------------------------------------
# main.scss
#
# Sass file for Areen template.
# Author: SaltTechno
#
# This is compressed CSS file. You get uncompressed version of
# this file and all source scss files with download.
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# [TABLE OF CONTENTS]
#
# 1. VARIABLES & MIXINS
# 2. COMMON - MARGIN & PADDING
# 3. SLIDERS
# 4. SECTIONS & TITLES
# 5. FEATURES
# 6. NAVBAR
# 7. LISTS
# 8. COUNTERS
# 9. BUTTONS
# 10. STEPS
# 11. TIMELINE
# 12. PRICING
# 13. OWL CAROUSEL
# 14. BLOG
# 15. PAGE LOADERS
# 16. FOOTER
# 17. PAGE HEADERS
# 18. SHORTCODES
# 19. TABS
# 20. TEAM
# 21. BLOG
# 22. CONTACT & MAP
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# 1. VARIABLES & MIXINS
--------------------------------------------------------------*/
@import 'https://fonts.googleapis.com/css?family=Poppins:600|Questrial';
html, body {
  font-family: "Questrial", sans-serif;
  font-weight: 400;
  color: #323232;
  font-size: 12px;
  line-height: 1.75em;
}

@media (min-width: 600px) {
  html, body {
    font-size: calc( 12px + (16 - 12) * ((100vw - 600px) / (1140 - 600)));
  }
}

@media (min-width: 1140px) {
  html, body {
    font-size: 16px;
  }
}

h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
}

p, blockquote, pre,
address,
dl, ol, ul,
table {
  margin-bottom: 1.75em;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #323232;
  clear: both;
}

h1, .h1 {
  font-size: 27.8543898685px;
  margin-top: 0.9424008253em;
  line-height: 1.1308809903em;
  margin-bottom: 0.1884801651em;
}

@media (min-width: 600px) {
  h1, .h1 {
    font-size: calc( 27.8543898685px + (37.1391864913 - 27.8543898685) * ((100vw - 600px) / (1140 - 600)));
  }
}

@media (min-width: 1140px) {
  h1, .h1 {
    font-size: 37.1391864913px;
    margin-top: 0.9424008253em;
    line-height: 1.1308809903em;
    margin-bottom: 0.1884801651em;
  }
}

h2, .h2 {
  font-size: 23.5370033519px;
  margin-top: 1.1152651681em;
  line-height: 1.3383182017em;
  margin-bottom: 0.2514831227em;
}

@media (min-width: 600px) {
  h2, .h2 {
    font-size: calc( 23.5370033519px + (31.3826711359 - 23.5370033519) * ((100vw - 600px) / (1140 - 600)));
  }
}

@media (min-width: 1140px) {
  h2, .h2 {
    font-size: 31.3826711359px;
    margin-top: 1.1152651681em;
    line-height: 1.3383182017em;
    margin-bottom: 0.2443756004em;
  }
}

h3, .h3 {
  font-size: 19.888804939px;
  margin-top: 1.3198379732em;
  line-height: 1.5838055678em;
  margin-bottom: 0.3037841038em;
}

@media (min-width: 600px) {
  h3, .h3 {
    font-size: calc( 19.888804939px + (26.5184065853 - 19.888804939) * ((100vw - 600px) / (1140 - 600)));
  }
}

@media (min-width: 1140px) {
  h3, .h3 {
    font-size: 26.5184065853px;
    margin-top: 1.3198379732em;
    line-height: 1.5838055678em;
    margin-bottom: 0.2938299765em;
  }
}

h4, .h4 {
  font-size: 16.8060715286px;
  margin-top: 1.5619355157em;
  line-height: 1.8743226189em;
  margin-bottom: 0.3681503615em;
}

@media (min-width: 600px) {
  h4, .h4 {
    font-size: calc( 16.8060715286px + (22.4080953714 - 16.8060715286) * ((100vw - 600px) / (1140 - 600)));
  }
}

@media (min-width: 1140px) {
  h4, .h4 {
    font-size: 22.4080953714px;
    margin-top: 1.5619355157em;
    line-height: 1.8743226189em;
    margin-bottom: 0.3542095469em;
  }
}

h5, .h5 {
  font-size: 14.2011569368px;
  margin-top: 1.8484409486em;
  line-height: 2.2181291384em;
  margin-bottom: 0.3696881897em;
}

@media (min-width: 600px) {
  h5, .h5 {
    font-size: calc( 14.2011569368px + (18.9348759157 - 14.2011569368) * ((100vw - 600px) / (1140 - 600)));
  }
}

@media (min-width: 1140px) {
  h5, .h5 {
    font-size: 18.9348759157px;
    margin-top: 1.8484409486em;
    line-height: 2.2181291384em;
    margin-bottom: 0.3696881897em;
  }
}

h6, .h6 {
  font-size: 12px;
  margin-top: 2.1875em;
  line-height: 2.625em;
  margin-bottom: 0.5104166667em;
}

@media (min-width: 600px) {
  h6, .h6 {
    font-size: calc( 12px + (16 - 12) * ((100vw - 600px) / (1140 - 600)));
  }
}

@media (min-width: 1140px) {
  h6, .h6 {
    font-size: 16px;
    margin-top: 2.1875em;
    line-height: 2.625em;
    margin-bottom: 0.4739583333em;
  }
}

blockquote {
  font-style: italic;
}

blockquote cite {
  font-style: normal;
}

pre {
  padding: 0.875em;
  margin-bottom: 1.75em;
}

pre code {
  padding: 0;
}

code {
  font-family: "Courier New", "Courier", "Lucida Sans Typewriter", "Lucida Typewriter", "monospace";
  padding: 0.0875em 0.2625em;
  line-height: 0;
}

big, small, sub, sup {
  line-height: 0;
}

abbr, acronym {
  border-bottom: 1px dotted currentColor;
  cursor: help;
}

address {
  font-style: normal;
}

dt {
  color: #323232;
  font-weight: bold;
}

ul {
  padding-left: 1.1em;
}

ol {
  padding-left: 1.4em;
}

fieldset {
  padding: 0.875em 1.75em 1.75em;
  border-width: 1px;
  border-style: solid;
  max-width: 100%;
  margin-bottom: 1.859375em;
}

@media (min-width: 1140px) {
  fieldset {
    margin-bottom: 2.05078125em;
  }
}

fieldset button, fieldset input[type="submit"] {
  margin-bottom: 0;
}

legend {
  color: #323232;
  font-weight: bold;
}

input[type="text"], input[type="email"], input[type="password"], input[type="date"], input[type="datetime-local"], input[type="color"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="url"], input[type="search"], textarea {
  display: block;
  max-width: 100%;
  padding: 0.4375em;
  font-size: 12px;
  margin-bottom: 1.2578125em;
}

@media (min-width: 600px) {
  input[type="text"], input[type="email"], input[type="password"], input[type="date"], input[type="datetime-local"], input[type="color"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="url"], input[type="search"], textarea {
    font-size: calc( 12px + (16 - 12) * ((100vw - 600px) / (1140 - 600)));
  }
}

@media (min-width: 1140px) {
  input[type="text"], input[type="email"], input[type="password"], input[type="date"], input[type="datetime-local"], input[type="color"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="url"], input[type="search"], textarea {
    font-size: 16px;
    margin-bottom: 0.533203125em;
  }
}

input[type="submit"], button {
  /* display: block; */
  cursor: pointer;
  font-size: 12px;
  padding: 0.4375em 1.75em;
  margin-bottom: 0;
}

@media (min-width: 600px) {
  input[type="submit"], button {
    font-size: calc( 12px + (16 - 12) * ((100vw - 600px) / (1140 - 600)));
  }
}

@media (min-width: 1140px) {
  input[type="submit"], button {
    font-size: 16px;
    margin-bottom: 0;
  }
}

label {
  display: block;
  padding-bottom: 0.21875em;
  margin-bottom: -0.1640625em;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  margin-bottom: 2.296875em;
}

@media (min-width: 1140px) {
  table {
    margin-bottom: 2.078125em;
  }
}

th {
  text-align: left;
  color: #323232;
  padding: 0.21875em 0.875em;
}

@media (min-width: 1140px) {
  th {
    padding: 0.4375em 0.875em;
  }
}

td {
  padding: 0.875em;
  padding: 0.21875em 0.875em;
}

@media (min-width: 1140px) {
  td {
    padding: 0.4375em 0.875em;
  }
}

/*--------------------------------------------------------------
# 2. COMMON
--------------------------------------------------------------*/
body {
  font-family: "Questrial", sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: #fff;
  color: #686868;
  text-rendering: optimizeLegibility !important;
  letter-spacing: 0.5px;
}

::-moz-selection {
  background-color: #323232;
  color: #fff;
}

::selection {
  background-color: #323232;
  color: #fff;
}

ul {
  list-style: none;
}

a {
  outline: none !important;
  text-decoration: none !important;
  color: #686868;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

a:hover {
  text-decoration: none;
  color: #e74c3c !important;
}

p a {
  color: #e74c3c;
}

.light-text {
  color: #c2c2c2;
}

.primary-color {
  color: #e74c3c;
}

.zero-padding {
  padding: 0px !important;
}

.mbn {
  margin-bottom: 0 !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mtn {
  margin-top: 0 !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mln {
  margin-left: 0;
}

.ml10 {
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.ml30 {
  margin-left: 30px;
}

.ml40 {
  margin-left: 40px;
}

.ml50 {
  margin-left: 50px;
}

.ml60 {
  margin-left: 60px;
}

.mrn {
  margin-right: 0;
}

.mr10 {
  margin-right: 10px;
}

.mr20 {
  margin-right: 20px;
}

.mr30 {
  margin-right: 30px;
}

.mr40 {
  margin-right: 40px;
}

.mr50 {
  margin-right: 50px;
}

.mr60 {
  margin-right: 60px;
}

.pbn {
  padding-bottom: 0 !important;
}

.pb10 {
  padding-bottom: 10px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb30 {
  padding-bottom: 30px;
}

.pb40 {
  padding-bottom: 40px;
}

.pb50 {
  padding-bottom: 50px;
}

.pb60 {
  padding-bottom: 60px;
}

.pb70 {
  padding-bottom: 70px;
}

.pb80 {
  padding-bottom: 80px;
}

.ptn {
  padding-top: 0 !important;
}

.pt10 {
  padding-top: 10px;
}

.pt20 {
  padding-top: 20px;
}

.pt30 {
  padding-top: 30px;
}

.pt40 {
  padding-top: 40px;
}

.pt50 {
  padding-top: 50px;
}

.pt60 {
  padding-top: 60px;
}

.pt70 {
  padding-top: 70px;
}

.pt80 {
  padding-top: 80px;
}

.pt90 {
  padding-top: 90px;
}

.pln {
  padding-left: 0;
}

.pl10 {
  padding-left: 10px;
}

.pl20 {
  padding-left: 20px;
}

.pl30 {
  padding-left: 30px;
}

.pl40 {
  padding-left: 40px;
}

.pl50 {
  padding-left: 50px;
}

.pl60 {
  padding-left: 60px;
}

.prn {
  padding-right: 0;
}

.pr10 {
  padding-right: 10px;
}

.pr20 {
  padding-right: 20px;
}

.pr30 {
  padding-right: 30px;
}

.pr40 {
  padding-right: 40px;
}

.pr50 {
  padding-right: 50px;
}

.pr60 {
  padding-right: 60px;
}

.mtm-80 {
  margin-top: -80px;
}

.st-img {
  /* border-radius: $border-radius-base; */
}

.breadcrumb {
  font-size: 90%;
}

.white-color {
  color: #fff !important;
}

.white-bg {
  background-color: #fff !important;
}

.gray-bg {
  background-color: #f6f6f6 !important;
}

/*--------------------------------------------------------------
# 3. SLIDERS
--------------------------------------------------------------*/
.NotGeneric-Title,
.tp-caption.NotGeneric-Title {
  color: white;
  font-size: 70px;
  line-height: 70px;
  font-weight: 600;
  font-style: normal;
  font-family: "Poppins", sans-serif;
  padding: 10px 0;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0 0 0 0;
}

.NotGeneric-SubTitle,
.tp-caption.NotGeneric-SubTitle {
  color: white;
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  font-family: "Poppins", sans-serif;
  padding: 0;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0 0 0 0;
  text-align: left;
  letter-spacing: 4px;
  text-align: left;
}

.NotGeneric-Icon,
.tp-caption.NotGeneric-Icon {
  color: white;
  font-size: 30px;
  line-height: 30px;
  font-weight: 600;
  font-style: normal;
  font-family: "Poppins", sans-serif;
  padding: 0;
  text-decoration: none;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0);
  border-style: solid;
  border-width: 0;
  border-radius: 0 0 0 0;
  text-align: left;
  letter-spacing: 3px;
  text-align: left;
}

.NotGeneric-Button,
.tp-caption.NotGeneric-Button {
  color: white;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  font-style: normal;
  font-family: "Poppins", sans-serif;
  padding: 10px 30px;
  text-decoration: none;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.5);
  border-style: solid;
  border-width: 1px;
  border-radius: 0 0 0 0;
  text-align: left;
  letter-spacing: 3px;
  text-align: left;
}

.NotGeneric-Button:hover,
.tp-caption.NotGeneric-Button:hover {
  color: white;
  text-decoration: none;
  background-color: transparent;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 0 0 0 0;
  cursor: pointer;
}

.NotGeneric-BigButton,
.tp-caption.NotGeneric-BigButton {
  color: white;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  font-style: normal;
  font-family: "Poppins", sans-serif;
  padding: 27px 30px;
  text-decoration: none;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.15);
  border-style: solid;
  border-width: 1px;
  border-radius: 0 0 0 0;
  text-align: left;
  letter-spacing: 3px;
}

.NotGeneric-BigButton:hover,
.tp-caption.NotGeneric-BigButton:hover {
  color: white;
  text-decoration: none;
  background-color: transparent;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 0 0 0 0;
  cursor: pointer;
}

.RotatingWords-TitleWhite,
.tp-caption.RotatingWords-TitleWhite {
  color: white;
  font-size: 70px;
  line-height: 70px;
  font-weight: 600;
  font-style: normal;
  font-family: "Poppins", sans-serif;
  padding: 0;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0 0 0 0;
}

.RotatingWords-Button,
.tp-caption.RotatingWords-Button {
  color: white;
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  font-family: "Poppins", sans-serif;
  padding: 20px 50px;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.15);
  border-style: solid;
  border-width: 2px;
  border-radius: 0 0 0 0;
  letter-spacing: 3px;
}

.RotatingWords-Button:hover,
.tp-caption.RotatingWords-Button:hover {
  color: white;
  text-decoration: none;
  background-color: transparent;
  border-color: white;
  border-style: solid;
  border-width: 2px;
  border-radius: 0 0 0 0;
  cursor: pointer;
}

.RotatingWords-SmallText,
.tp-caption.RotatingWords-SmallText {
  color: white;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  font-family: "Poppins", sans-serif;
  padding: 0;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0 0 0 0;
  text-shadow: none;
}

.FullSiteBlock-SubTitle,
.tp-caption.FullSiteBlock-SubTitle {
  color: #333333;
  font-size: 25px;
  line-height: 34px;
  font-weight: 600;
  font-style: normal;
  font-family: "Poppins", sans-serif;
  padding: 0;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0 0 0 0;
}

.FullSiteBlock-FooterLink,
.tp-caption.FullSiteBlock-FooterLink {
  color: #555555;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400 !important;
  font-style: normal;
  font-family: "Questrial", sans-serif;
  padding: 0;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0 0 0 0;
}

.FullSiteBlock-FooterLink:hover,
.tp-caption.FullSiteBlock-FooterLink:hover {
  color: #0096ff;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0 0 0 0;
  cursor: pointer;
}

.fb-share-button.fb_iframe_widget iframe {
  width: 115px !important;
}

#tp-socialwrapper {
  opacity: 0;
}

.FullSiteBlock-SubTitle,
.tp-caption.FullSiteBlock-SubTitle {
  color: #333333;
  font-size: 25px;
  line-height: 34px;
  font-weight: 400;
  font-style: normal;
  font-family: "Questrial", sans-serif;
  padding: 0;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0 0 0 0;
}

.FullSiteBlock-Title,
.tp-caption.FullSiteBlock-Title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.CreativeFrontPage-Btn,
.tp-caption.CreativeFrontPage-Btn {
  color: white;
  font-size: 14px;
  line-height: 60px;
  font-style: normal;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  padding: 0 50px;
  text-decoration: none;
  text-align: left;
  background-color: #00685c;
  border-color: black;
  border-style: solid;
  border-width: 0;
  border-radius: 6px;
  letter-spacing: 2px;
}

.CreativeFrontPage-Btn:hover,
.tp-caption.CreativeFrontPage-Btn:hover {
  color: white;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.25);
  border-color: black;
  border-style: solid;
  border-width: 0;
  border-radius: 6px;
  cursor: pointer;
}

.tp-flip-index {
  z-index: 1000 !important;
}

.hermes .tp-bullet {
  overflow: hidden;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px white;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  position: absolute;
}

.hermes .tp-bullet:hover {
  background-color: rgba(0, 0, 0, 0.21);
}

.hermes .tp-bullet:after {
  content: ' ';
  position: absolute;
  bottom: 0;
  height: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 1px white;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.hermes .tp-bullet.selected:after {
  height: 100%;
}

/*--------------------------------------------------------------
# 4. SECTIONS & TITLES
--------------------------------------------------------------*/
.section-title {
  margin-bottom: 60px;
}

.section-title .small-title {
  color: #e74c3c;
  margin-top: 0;
}

.section-title .main-title {
  margin-top: 0;
}

.section-title .sub-title {
  font-size: 1em;
}

.section-title.page-title {
  padding-top: 60px;
  padding-bottom: 60px;
}

section.content-section {
  padding: 100px 0px;
  background: #fff;
}

section.content-section.black-section {
  background-color: #323232;
  color: #fff;
}

section.content-section.black-section h1, section.content-section.black-section h2, section.content-section.black-section h3, section.content-section.black-section h4, section.content-section.black-section h5, section.content-section.black-section h6 {
  color: #fff;
}

section.content-section.black-section .small-title {
  color: #e74c3c;
}

section.content-section.gray-section {
  background: #f6f6f6;
}

.parallax-section .primary-grad {
  background: -webkit-linear-gradient( left , rgba(231, 76, 60, 0.8), rgba(168, 35, 21, 0.6));
  background: linear-gradient(to right, rgba(231, 76, 60, 0.8), rgba(168, 35, 21, 0.6));
  color: #fff;
}

.parallax-section .primary-grad h1, .parallax-section .primary-grad h2, .parallax-section .primary-grad h3, .parallax-section .primary-grad h4, .parallax-section .primary-grad h5, .parallax-section .primary-grad h6 {
  color: #fff;
}

.parallax-section .black-grad {
  background: #323232;
  background: rgba(50, 50, 50, 0.6);
  color: #fff;
}

.parallax-section .black-grad h1, .parallax-section .black-grad h2, .parallax-section .black-grad h3, .parallax-section .black-grad h4, .parallax-section .black-grad h5, .parallax-section .black-grad h6 {
  color: #fff;
}

.black-half {
  background: #323232;
  color: #fff;
  padding-left: 2.618em;
  padding-right: 2.618em;
}

.black-half h1, .black-half h2, .black-half h3, .black-half h4, .black-half h5, .black-half h6 {
  color: #fff;
}

/*--------------------------------------------------------------
# 5. FEATURES
--------------------------------------------------------------*/
.feature-box {
  background: #f6f6f6;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  padding: 2.618em 1em;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.feature-box h4 {
  margin-top: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.feature-box p {
  margin-bottom: 0;
}

.feature-box .icon {
  display: block;
  width: 85px;
  height: 85px;
  font-size: 50px;
  line-height: 85px;
  margin: auto;
  border-radius: 50%;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  margin-bottom: 10px;
}

.feature-box .bg-icon {
  position: absolute;
  color: #9d9d9d;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  bottom: -20px;
  right: -20px;
  font-size: 150px;
  opacity: 0.1;
}

.feature-box:hover {
  background: #323232;
  color: #fff;
}

.feature-box:hover .icon {
  color: #e74c3c;
  background-color: #fff;
}

.feature-box:hover .bg-icon {
  color: #f6f6f6;
}

.feature-box:hover h4 {
  color: #fff;
}

.feature-box-2 .box-left {
  float: left;
  width: 15%;
}

.feature-box-2 .box-left .icon {
  font-size: 52px;
  color: #e74c3c;
}

.feature-box-2 .box-right {
  margin-bottom: 1.618em;
  width: 85%;
  float: left;
}

.feature-box-2 .box-right h3, .feature-box-2 .box-right h4, .feature-box-2 .box-right h5 {
  margin-top: 0;
}

.feature-box-3 {
  background: #fff;
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.15);
  padding: 2.618em 1em;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.feature-box-3 h4, .feature-box-3 h5 {
  margin-top: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.feature-box-3 p {
  margin-bottom: 0;
}

.feature-box-3 .icon {
  font-size: 50px;
  line-height: 85px;
  margin: auto;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  margin-bottom: 10px;
  color: #e74c3c;
}

.feature-box-3:hover {
  background: #323232;
  color: #fff;
}

.feature-box-3:hover .icon {
  color: #e74c3c;
}

.feature-box-3:hover .bg-icon {
  color: #f6f6f6;
}

.feature-box-3:hover h4, .feature-box-3:hover h5 {
  color: #fff;
}

.feature-box-4 .box-left {
  float: left;
  width: 15%;
}

.feature-box-4 .box-left .icon {
  font-size: 40px;
  color: #e74c3c;
}

.feature-box-4 .box-right {
  margin-bottom: 1.618em;
  width: 85%;
  float: left;
}

.feature-box-4 .box-right h3, .feature-box-4 .box-right h4, .feature-box-4 .box-right h5 {
  margin-top: 0;
}

@media (max-width: 500px) {
  .feature-box-2 .box-left .icon {
    font-size: 42px;
  }
}

/*--------------------------------------------------------------
# 6. NAVBAR
--------------------------------------------------------------*/
@media (min-width: 992px) {
  ul.nav li.dropdown:hover > ul.dropdown-menu {
    display: block;
  }
}

.navbar-nav > li > a {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 30px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.navbar-fixed-top {
  border: 0px;
}

.navbar {
  margin-bottom: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.navbar a {
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  font-size: 90%;
}

.navbar-header {
  position: relative;
  z-index: 2;
}

.navbar-brand {
  height: 60px;
}

.navbar-brand img {
  height: 25px;
}

.navbar-brand .dark-logo {
  display: none;
}

.nav > li > a:hover, .nav > li > a:focus {
  background: #e74c3c;
  color: #fff !important;
}

.nav > li:hover a, .nav > li:focus a {
  background: #e74c3c;
  color: #fff !important;
}

.me-menu .me-menu-content {
  padding: 0px;
}

.me-menu .nav, .me-menu .collapse, .me-menu .dropup, .me-menu .dropdown {
  position: relative;
}

.nav-btn {
  margin-top: 10px;
  display: inline-block;
}

.dropdown-menu {
  background: #e74c3c;
  border: 0px;
  padding: 0;
  color: #fff;
  min-width: 240px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
}

.dropdown-menu a {
  color: #fff;
  display: block;
  padding: 3px 0;
  position: relative;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.dropdown-menu a:hover {
  color: #fff !important;
  padding-left: 6px;
}

.dropdown-menu a.btn {
  padding: 0;
}

.dropdown-menu .has-submenu {
  position: relative;
}

.dropdown-menu .has-submenu:hover .me-submenu {
  display: block;
}

.dropdown-menu .me-submenu {
  position: absolute;
  left: 220px;
  padding: 20px;
  padding-left: 20px !important;
  top: 0;
  min-width: 240px;
  background: #e74c3c;
  display: none;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
}

.dropdown-menu .glyphicon {
  font-size: 80%;
}

.dropdown-menu .glyphicon.glyphicon-chevron-right {
  float: right;
  line-height: inherit;
}

.dropdown-menu .me-menu-content {
  display: table;
}

.dropdown-menu .nav-col {
  display: table-cell;
  min-width: 240px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.dropdown-menu .nav-col h1,
.dropdown-menu .nav-col h2,
.dropdown-menu .nav-col h3,
.dropdown-menu .nav-col h4,
.dropdown-menu .nav-col h5,
.dropdown-menu .nav-col h6 {
  margin-top: 0;
  margin-left: 0;
  font-weight: 400;
  color: #fff;
  padding-left: 20px;
}

.dropdown-menu .nav-col ul {
  padding-left: 20px;
  padding-right: 10px;
}

.dropdown-menu .nav-col.dark-bg {
  background: #323232;
}

.dd-left .dropdown-menu {
  right: 0;
  left: auto;
}

.body-scrolled .dark-logo {
  display: inline-block;
}

.body-scrolled .light-logo {
  display: none;
}

.body-scrolled .navbar {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  background: #fff;
}

.body-scrolled .navbar .navbar-nav > li > a {
  color: #323232;
}

.body-scrolled .navbar .nav > li > a:hover, .body-scrolled .navbar .nav > li > a:focus {
  background: #e74c3c;
  color: #fff;
}

.dark-menu .dark-logo {
  display: inline-block;
}

.dark-menu .light-logo {
  display: none;
}

.dark-menu .navbar .navbar-nav > li > a {
  color: #323232;
}

.dark-menu .navbar .nav > li > a:hover, .dark-menu .navbar .nav > li > a:focus {
  background: #e74c3c;
  color: #fff;
}

.navbar-toggle {
  background-color: #e74c3c;
}

.navbar-toggle .icon-bar {
  background-color: #fff;
}

.dropdown-menu .nav-col ul {
  padding-right: 0;
}

.dropdown-menu .nav-col ul li > a {
  padding-right: 10px;
}

@media (max-width: 991px) {
  .navbar-nav {
    float: none !important;
  }
  .navbar-collapse.collapse.in {
    background: #323232;
  }
  .nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
    background: #e74c3c;
  }
  .nav > li:focus a, .nav > li:hover a, .nav > li > a:focus, .nav > li > a:hover {
    background: transparent;
  }
  .dropdown-menu .nav-col ul {
    padding-right: 0;
  }
  .body-scrolled .navbar .navbar-nav > li > a {
    color: #fff;
  }
  .dark-menu .navbar .navbar-nav > li > a {
    color: #fff;
  }
}

.navbar .nav .open > a, .navbar .nav .open > a:focus, .navbar .nav .open > a:hover {
  background-color: #e74c3c;
}

/*--------------------------------------------------------------
# 7. LISTS
--------------------------------------------------------------*/
.ul-style-3 {
  line-height: 34px;
}

.ul-style-3 li {
  margin-bottom: 10px;
}

.ul-style-3 .icon {
  background: #e74c3c;
  color: #fff;
  width: 34px;
  height: 34px;
  line-height: 34px;
  font-size: 22px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}

/*--------------------------------------------------------------
# 8. COUNTERS
--------------------------------------------------------------*/
.counter-box-2 {
  background-color: #f6f6f6;
  color: #323232;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  text-align: center;
  padding: 30px;
  border-radius: 6px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.counter-box-2 p {
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}

.counter-box-2 .icon {
  display: block;
  font-size: 40px;
  color: #e74c3c;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.counter-box-2 .counter {
  font-size: 2.618em;
  margin: 15px;
  display: block;
}

.counter-box-2 .bg-icon {
  position: absolute;
  right: -20px;
  bottom: -20px;
  font-size: 120px;
  color: #323232;
  opacity: 0.75;
  z-index: 0;
}

.counter-box-2:hover {
  background: transparent;
  color: #fff;
}

.counter-box-2:hover .icon {
  color: #fff;
}

.counter-box-3 {
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  text-align: center;
  padding: 30px;
  border-radius: 6px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.counter-box-3 p {
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}

.counter-box-3 .icon {
  display: block;
  font-size: 40px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.counter-box-3 .counter {
  font-size: 2.618em;
  margin: 15px;
  display: block;
}

.counter-box-3 .bg-icon {
  position: absolute;
  right: -20px;
  bottom: -20px;
  font-size: 120px;
  color: #323232;
  opacity: 0.75;
  z-index: 0;
}

.counter-box-3:hover {
  background: #f6f6f6;
  color: #323232;
}

.counter-box-3:hover .icon {
  color: #e74c3c;
}

/*--------------------------------------------------------------
# 9. BUTTONS
--------------------------------------------------------------*/
.st-btn {
  letter-spacing: 1px;
  font-size: 1em;
  line-height: 2;
  text-transform: capitalize;
  padding: 0.618em 2.618em;
  background: #fff;
  color: #e74c3c;
  border: 0;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
}

.st-btn.btn-rounded {
  border-radius: 30px;
}

.st-btn.btn-rounded[class*="hvr-"]:before {
  border-radius: 30px;
}

.st-btn:hover {
  color: #fff !important;
  background: #e74c3c;
}

.st-btn.btn-primary {
  background: #e74c3c;
  color: #fff;
}

.st-btn.btn-primary:hover {
  color: #e74c3c !important;
  background: #fff;
}

.st-btn.small-btn {
  font-size: 80%;
}

/*--------------------------------------------------------------
# 10. STEPS
--------------------------------------------------------------*/
.steps {
  counter-reset: st-counter;
}

.steps .step-box {
  position: relative;
}

.steps .step-box:before {
  content: counter(st-counter);
  counter-increment: st-counter;
  position: absolute;
  font-family: "Poppins", sans-serif;
  color: #e9e9e9;
  font-size: 4.236em;
  font-style: italic;
  z-index: 0;
  width: 90%;
  top: 40px;
  left: 0px;
  text-align: right;
  padding-right: 20px;
}

/*--------------------------------------------------------------
# 11. TIMELINE
--------------------------------------------------------------*/
.timeline {
  position: relative;
}

.timeline .tm-icon {
  width: 70px;
  height: 70px;
  text-align: center;
  background-color: #f6f6f6;
  border-radius: 50%;
  position: absolute;
}

.timeline .tm-icon span {
  color: #e74c3c;
  font-size: 38px;
  line-height: 70px;
}

.timeline .tm-detail {
  padding-left: 90px;
  margin-bottom: 50px;
  color: #686868;
  font-size: 1em;
}

.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 30px;
  height: 100%;
  width: 2px;
  background: #f6f6f6;
}

.black-section .timeline .tm-icon, .black-half .timeline .tm-icon {
  background-color: #fff;
}

.black-section .timeline .tm-detail, .black-half .timeline .tm-detail {
  color: #f6f6f6;
}

.black-section .timeline::before, .black-half .timeline::before {
  background: #fff;
}

/*--------------------------------------------------------------
# 12. PRICING
--------------------------------------------------------------*/
.pricing-box {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
}

.pricing-box .pricing-head {
  background-color: #f6f6f6;
  border-radius: 6px 6px 0 0;
  text-align: center;
  border-top: 3px solid #e74c3c;
  padding: 1.618em;
  position: relative;
  overflow: hidden;
}

.pricing-box .pricing-head h1, .pricing-box .pricing-head h2, .pricing-box .pricing-head h3, .pricing-box .pricing-head h4, .pricing-box .pricing-head h5, .pricing-box .pricing-head h6 {
  margin: 0;
}

.pricing-box .pricing-head .striked {
  text-decoration: line-through;
}

.pricing-box .pricing-head .bg-icon {
  position: absolute;
  bottom: -20px;
  right: -20px;
  font-size: 120px;
  color: #9d9d9d;
  opacity: 0.2;
}

.pricing-box .pricing-body {
  padding: 1em;
}

.pricing-box .pricing-body ul li {
  line-height: 2.25;
}

.pricing-box .pricing-body ul li .icon {
  float: right;
}

.pricing-box .pricing-foot a {
  display: block;
  padding: 1em;
  background: #f6f6f6;
  color: #323232;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: 1px;
  border-radius: 0 0 6px 6px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.pricing-box .pricing-foot a:hover {
  background: #e74c3c;
  color: #fff !important;
}

.pricing-box.popular .pricing-head {
  background: #323232;
  color: #fff;
}

.pricing-box.popular .pricing-head h1, .pricing-box.popular .pricing-head h2, .pricing-box.popular .pricing-head h3, .pricing-box.popular .pricing-head h4, .pricing-box.popular .pricing-head h5, .pricing-box.popular .pricing-head h6 {
  color: #fff;
}

/*--------------------------------------------------------------
# 13. OWL CAROUSEL
--------------------------------------------------------------*/
.owl-carousel {
  cursor: ew-resize;
}

/*--------------------------------------------------------------
# 14. BLOG
--------------------------------------------------------------*/
.blogs-grid [class*="col-"] {
  padding: 0;
}

.latest-post {
  position: relative;
}

.latest-post .blog-info {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1em;
  overflow: hidden;
  color: #fff;
  background: -webkit-linear-gradient( left top, rgba(168, 35, 21, 0.9), rgba(231, 76, 60, 0.5));
  background: linear-gradient(to right bottom, rgba(168, 35, 21, 0.9), rgba(231, 76, 60, 0.5));
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.latest-post .blog-info h1, .latest-post .blog-info h2, .latest-post .blog-info h3, .latest-post .blog-info h4, .latest-post .blog-info h5, .latest-post .blog-info h6, .latest-post .blog-info a {
  color: #fff !important;
  margin: 0;
}

.latest-post .blog-info .blog-meta {
  font-size: 90%;
}

.latest-post .blog-info .blog-excerpt {
  margin-bottom: 0;
}

.latest-post:hover .blog-info {
  opacity: 1;
}

/*--------------------------------------------------------------
# 15. PAGE LOADERS
--------------------------------------------------------------*/
.page-loader {
  position: fixed;
  z-index: 10001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f6f6f6;
}

.page-loader img.logo-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.spinner {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #e74c3c;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

/* spinner-2 */
.spinner-2 {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #e74c3c;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

/* spinner-3 */
.spinner-3 {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #e74c3c;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/* spinner-4 */
.spinner-4 {
  margin: auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner-4 > div {
  background-color: #333;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner-4 .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner-4 .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner-4 .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner-4 .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  100%,
  40% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  100%,
  40% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

/* spinner-5 */
.spinner-5 {
  margin: auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.cube1,
.cube2 {
  background-color: #333;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
  animation: sk-cubemove 1.8s infinite ease-in-out;
}

.cube2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-cubemove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes sk-cubemove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  }
  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
  }
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}

/* spinner-6 */
.spinner-6 {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner-6 > div {
  width: 18px;
  height: 18px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner-6 .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner-6 .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.border-0 {
  border-radius: 0 !important;
}

/*--------------------------------------------------------------
# 16. FOOTER
--------------------------------------------------------------*/
.footer {
  background: #222222;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #222222, #434343);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #222222, #434343);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #f6f6f6;
}

.footer h5, .footer h6 {
  color: #f6f6f6;
  text-transform: uppercase;
}

.footer p {
  margin-bottom: 0;
}

.footer p i {
  display: inline-block;
  width: 25px;
}

.footer .top-container {
  padding-top: 80px;
  padding-bottom: 80px;
}

.footer .footer-bottom {
  padding: 20px 0;
  background: #222;
  font-size: 90%;
  color: #f6f6f6;
}

.footer .footer-bottom a {
  padding: 0 15px;
}

.footer .social-link {
  padding: 10px;
  font-size: 1.618em;
}

/*--------------------------------------------------------------
# 17. PAGE HEADER
--------------------------------------------------------------*/
.page-header {
  background: #f6f6f6;
  padding: 150px 0;
  margin-top: 0;
}

.page-header.pattern {
  background-image: url(../images/pattern.png);
  background-repeat: repeat;
}

.page-header .breadcrumb {
  background: transparent;
}

/*--------------------------------------------------------------
# 18. SHORTCODES
--------------------------------------------------------------*/
.acc-default .panel-title {
  font-size: 1em;
  text-transform: capitalize;
}

.acc-default .panel-title a {
  display: block;
}

.acc-wo-background .panel-title {
  font-size: 1em;
  text-transform: capitalize;
}

.acc-wo-background .panel-title a {
  display: block;
}

.acc-wo-background .panel-heading {
  background-color: transparent;
}

.acc-wo-gap .panel-title {
  font-size: 1em;
  text-transform: capitalize;
}

.acc-wo-gap .panel-title a {
  display: block;
}

.acc-wo-gap.panel-group .panel {
  margin-top: -1px;
}

.acc-wo-border .panel-title {
  font-size: 1em;
  text-transform: capitalize;
}

.acc-wo-border .panel-title a {
  display: block;
}

.acc-wo-border .panel {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.acc-faq-1 .panel-title {
  font-size: 1em;
  text-transform: capitalize;
}

.acc-faq-1 .panel-title .icon {
  transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.acc-faq-1 .panel-title .collapsed .icon {
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

.acc-faq-1 .panel-body {
  padding-left: 40px;
}

.acc-faq-1 .panel-title a {
  display: block;
}

.acc-faq-1 .panel {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.acc-faq-1 .panel-heading {
  background: transparent;
  border: 0;
}

.acc-faq-1 .panel-body {
  border: 0 !important;
}

.acc-faq-2 .panel-title {
  font-size: 1em;
  text-transform: capitalize;
}

.acc-faq-2 .panel-title .icon {
  transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  float: right;
  color: #fff;
  padding: 10px 0px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.acc-faq-2 .panel-title .collapsed .icon {
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

.acc-faq-2 .panel-body {
  padding-left: 20px;
}

.acc-faq-2 .panel-title a {
  display: block;
}

.acc-faq-2 .panel {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.acc-faq-2 .panel-heading {
  background: #323232;
  color: #fff;
  border: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.acc-faq-2 .panel-heading:hover {
  background: #323232;
}

.acc-faq-2 .panel-body {
  border: 0 !important;
}

.nav-tabs > li > a {
  border-radius: 0;
}

.nav-tabs > li > a .icon {
  font-size: 16px;
}

blockquote {
  padding: 10px 20px;
}

blockquote small {
  padding-top: 10px;
}

.blockquote-default {
  border-left: 5px solid #dddddd;
}

.blockquote-default.primary-color {
  border-left-color: #e74c3c;
}

.blockquote-reverse {
  border-left: 0;
  border-right: 5px solid #dddddd;
}

.blockquote-reverse.primary-color {
  border-right-color: #e74c3c;
}

.blockquote-2 {
  border-left: 0;
  position: relative;
}

.blockquote-2::before {
  color: rgba(0, 0, 0, 0.1);
  content: "“";
  display: block;
  font-size: 140px;
  left: 5px;
  position: absolute;
  top: 15px;
}

.blockquote-reverse-2 {
  border-left: 0;
  text-align: right;
}

.blockquote-reverse-2::before {
  color: rgba(0, 0, 0, 0.1);
  content: "”";
  display: block;
  font-size: 140px;
  right: 5px;
  position: absolute;
  top: 15px;
}

.gray-bg .blockquote-default {
  border-left: 5px solid #c3c3c3;
}

.gray-bg .blockquote-reverse {
  border-right: 5px solid #c3c3c3;
  border-left: 0;
}

.bt-grid [class*=col-] {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #d9d9d9;
  border: 1px solid #ccc;
}

/*--------------------------------------------------------------
# 19. TABS
--------------------------------------------------------------*/
.tabs-section .nav.nav-pills {
  display: table;
  width: 100%;
}

.tabs-section .nav.nav-pills a i {
  display: block;
  width: 50px;
  height: 50px;
  font-size: 42px;
  line-height: 50px;
  margin: auto;
}

.tabs-section .nav-pills > li {
  display: table-cell;
  float: none;
}

.tabs-section .nav-pills > li > a {
  color: #686868;
}

.tabs-section .nav-pills > li.active > a,
.tabs-section .nav-pills > li.active > a:focus,
.tabs-section .nav-pills > li.active > a:hover {
  background-color: transparent !important;
  color: #e74c3c !important;
}

.tabs-section .tab-pane {
  padding: 40px 0 30px;
}

.tab-style-2 .nav-tabs {
  border-bottom: 0;
}

.tab-style-2 .nav-tabs > li > a {
  padding: 10px 25px;
}

.tab-style-2 .nav-tabs > li > a:hover {
  background: none;
  border-color: transparent;
}

.tab-style-2 .nav-tabs > li.active > a,
.tab-style-2 .nav-tabs > li.active > a:focus,
.tab-style-2 .nav-tabs > li.active > a:hover {
  color: #323232;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #aaaaaa;
}

.tab-style-2 .tab-content {
  padding: 20px;
}

.tab-style-1 .nav-tabs {
  border-bottom: 1px solid #dddddd;
  margin: 0;
  padding: 0;
  border-bottom-color: #dddddd;
}

.tab-style-1 .nav-tabs li {
  display: inline-block;
  float: none;
}

.tab-style-1 .nav-tabs > li > a {
  background: #dddddd;
  border-bottom: none;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-top: 3px solid #4c4c4c;
}

.tab-style-1 .nav-tabs > li.active > a,
.tab-style-1 .nav-tabs > li.active > a:focus,
.tab-style-1 .nav-tabs > li.active > a:hover {
  background: #fff;
  border-left-color: #dddddd;
  border-right-color: #dddddd;
  border-top: 3px solid #e74c3c;
  border-bottom-color: transparent;
}

.tab-style-1 .tab-content {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
  background-color: #fff;
  border: 1px solid #dddddd;
  border-top: 0;
  padding: 15px;
}

.tab-style-3 .nav-tabs {
  border-bottom: 0;
  margin: 0;
  padding: 0;
  display: table;
  width: 100%;
}

.tab-style-3 .nav-tabs li {
  display: table-cell;
  text-align: center;
  float: none;
}

.tab-style-3 .nav-tabs > li > a {
  background: #dddddd;
  border-bottom: none;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-top: 3px solid #4c4c4c;
  margin-right: 0;
  color: #4c4c4c;
}

.tab-style-3 .nav-tabs > li.active > a,
.tab-style-3 .nav-tabs > li.active > a:focus,
.tab-style-3 .nav-tabs > li.active > a:hover {
  background: #fff;
  border-left-color: #dddddd;
  border-right-color: #dddddd;
  border-top: 3px solid #e74c3c;
  border-bottom: 1px solid #FFF;
  color: #323232;
}

.tab-style-3 .tab-content {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
  background-color: #fff;
  border: 1px solid #dddddd;
  border-top: 0;
  padding: 15px;
}

.tab-style-4 .nav-tabs {
  border-top: 0;
  margin: 0;
  padding: 0;
  display: table;
  width: 100%;
}

.tab-style-4 .nav-tabs li {
  display: table-cell;
  text-align: center;
  float: none;
}

.tab-style-4 .nav-tabs > li > a {
  background: #dddddd;
  border-top: none;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-bottom: 3px solid #4c4c4c;
  margin-right: 0;
  color: #4c4c4c;
}

.tab-style-4 .nav-tabs > li.active > a,
.tab-style-4 .nav-tabs > li.active > a:focus,
.tab-style-4 .nav-tabs > li.active > a:hover {
  background: #fff;
  border-left-color: #dddddd;
  border-right-color: #dddddd;
  border-bottom: 3px solid #e74c3c;
  border-top: 1px solid #FFF;
  color: #323232;
}

.tab-style-4 .tab-content {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
  background-color: #fff;
  border: 1px solid #dddddd;
  border-bottom: 0;
  padding: 15px;
}

.tab-style-5 .nav-tabs {
  border-bottom: 0;
  text-align: center;
}

.tab-style-5 .nav-tabs > li {
  float: none;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.tab-style-5 .nav-tabs > li > a {
  padding: 10px 25px;
  color: #aaaaaa;
}

.tab-style-5 .nav-tabs > li > a:hover {
  background: none;
  border-color: transparent;
}

.tab-style-5 .nav-tabs > li.active > a,
.tab-style-5 .nav-tabs > li.active > a:focus,
.tab-style-5 .nav-tabs > li.active > a:hover {
  color: #323232;
  background-color: transparent;
  border: 1px solid #686868;
  border-radius: 30px;
}

.tab-style-5 .tab-content {
  padding: 30px 20px 20px;
  text-align: center;
  line-height: 26px;
}

.tab-style-6 .nav-tabs {
  border-right: 1px solid #dddddd;
  margin: 0;
  padding: 0;
  border-right-color: #dddddd;
}

.tab-style-6 .nav-tabs > li > a {
  background: #dddddd;
  border-right: none;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  border-left: 3px solid #4c4c4c;
}

.tab-style-6 .nav-tabs > li.active > a,
.tab-style-6 .nav-tabs > li.active > a:focus,
.tab-style-6 .nav-tabs > li.active > a:hover {
  background: #fff;
  border-top-color: #dddddd;
  border-bottom-color: #dddddd;
  border-left: 3px solid #e74c3c;
  border-right-color: transparent;
}

.tab-style-6 .tab-content {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
  background-color: #fff;
  border: 1px solid #dddddd;
  padding: 15px;
}

.tab-style-6 .nav-content-div {
  z-index: 1;
}

.tab-style-6 .nav-tab-div {
  z-index: 2;
  margin-right: -1px;
}

.tab-style-7 .nav-tabs {
  border-left: 1px solid #dddddd;
  margin: 0;
  padding: 0;
  border-left-color: #dddddd;
}

.tab-style-7 .nav-tabs > li > a {
  background: #dddddd;
  border-left: none;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  border-right: 3px solid #4c4c4c;
}

.tab-style-7 .nav-tabs > li.active > a,
.tab-style-7 .nav-tabs > li.active > a:focus,
.tab-style-7 .nav-tabs > li.active > a:hover {
  background: #fff;
  border-top-color: #dddddd;
  border-bottom-color: #dddddd;
  border-right: 3px solid #e74c3c;
  border-left-color: transparent;
}

.tab-style-7 .tab-content {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
  background-color: #fff;
  border: 1px solid #dddddd;
  padding: 15px;
}

.tab-style-7 .nav-content-div {
  z-index: 1;
}

.tab-style-7 .nav-tab-div {
  z-index: 2;
  margin-left: -1px;
}

.tab-style-8 .nav-tabs {
  border-bottom: 0;
  text-align: center;
}

.tab-style-8 .nav-tabs > li {
  float: none;
  display: inline-block;
  margin-left: 0;
  margin-right: 0;
}

.tab-style-8 .nav-tabs > li > a {
  padding: 10px 25px;
  color: #4c4c4c;
  border-bottom: 3px solid #dddddd;
}

.tab-style-8 .nav-tabs > li > a:hover {
  background: none;
  border-color: transparent;
}

.tab-style-8 .nav-tabs > li.active > a,
.tab-style-8 .nav-tabs > li.active > a:focus,
.tab-style-8 .nav-tabs > li.active > a:hover {
  color: #e74c3c;
  background-color: transparent;
  border: 0;
  border-bottom: 3px solid #e74c3c;
}

.tab-style-8 .tab-content {
  padding: 30px 20px 20px;
  text-align: center;
  line-height: 26px;
}

.tab-style-9 .nav-tabs {
  border-bottom: 0;
  text-align: center;
}

.tab-style-9 .nav-tabs > li {
  float: none;
  display: inline-block;
  margin-left: 0;
  margin-right: 0;
}

.tab-style-9 .nav-tabs > li > a {
  padding: 10px 25px;
  color: #b5b5b5;
  border-bottom: 3px solid #b5b5b5;
}

.tab-style-9 .nav-tabs > li > a .icon {
  display: block;
  width: 80px;
  height: 80px;
  border: 2px dotted #b5b5b5;
  margin: auto;
  margin-bottom: 10px;
  line-height: 80px;
  font-size: 44px;
  border-radius: 50%;
}

.tab-style-9 .nav-tabs > li > a:hover {
  background: none;
  border-color: transparent;
}

.tab-style-9 .nav-tabs > li.active > a,
.tab-style-9 .nav-tabs > li.active > a:focus,
.tab-style-9 .nav-tabs > li.active > a:hover {
  color: #e74c3c;
  background-color: transparent;
  border: 0;
  border-bottom: 3px solid #e74c3c;
}

.tab-style-9 .nav-tabs > li.active > a .icon,
.tab-style-9 .nav-tabs > li.active > a:focus .icon,
.tab-style-9 .nav-tabs > li.active > a:hover .icon {
  border-color: #e74c3c;
}

.tab-style-9 .tab-content {
  padding: 30px 20px 20px;
  text-align: center;
  line-height: 26px;
}

.stat-box {
  padding: 15px;
  border: 1px solid #dddddd;
  margin-left: -1px;
  margin-top: -1px;
}

.stat-box span.icon {
  font-size: 42px;
  display: block;
}

.stat-box h5 {
  font-size: 18px;
  display: inline-block;
}

.stat-box h6 {
  font-size: 12px;
  display: inline-block;
}

.stat-box:hover {
  background: #e74c3c;
  color: #fff;
}

.stat-box:hover h5 {
  color: #fff;
}

.stat-box:hover h6 {
  color: #fff;
}

.tab-style-10 .nav-tabs {
  border-right: 0px;
  margin: 0;
  padding: 0;
}

.tab-style-10 .nav-tabs > li > a {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-left: 3px solid #4c4c4c;
  color: #686868;
}

.tab-style-10 .nav-tabs > li.active > a,
.tab-style-10 .nav-tabs > li.active > a:focus,
.tab-style-10 .nav-tabs > li.active > a:hover {
  border-top-color: #dddddd;
  border-bottom-color: #dddddd;
  border-left: 3px solid #e74c3c;
  border-right-color: #dddddd;
  color: #e74c3c;
}

.tab-style-10 .tab-content {
  border: 0px;
  padding: 15px 25px;
}

.tab-style-10 .nav-content-div {
  z-index: 1;
}

.tab-style-10 .nav-tab-div {
  z-index: 2;
}

.nav-tabs > li:focus a, .nav-tabs > li:hover a, .nav-tabs > li > a:focus, .nav-tabs > li > a:hover {
  color: #e74c3c !important;
}

/*--------------------------------------------------------------
# 20. TEAM
--------------------------------------------------------------*/
.team-name {
  margin-top: 10px;
  margin-bottom: 0px;
  text-align: center;
  font-size: 1em;
}

.team-post {
  margin-top: 0;
  color: #e74c3c;
  margin-bottom: 15px;
  text-align: center;
  line-height: 1;
  font-size: 0.9em;
}

.team-member p {
  text-align: center;
  font-size: 0.85em;
  line-height: 1.5em;
}

/*--------------------------------------------------------------
# 21. BLOG
--------------------------------------------------------------*/
.entry {
  margin-bottom: 50px;
}

.entry .entry-title h4 {
  margin-top: 5px;
}

/**
 * ----------------------------------------------------------------------------------------
 * 22. CONTACT & MAP
 * ----------------------------------------------------------------------------------------
 */
#fullWidthMap {
  height: 400px;
}

.contact-info p {
  color: #686868;
}

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], input:not([type]), textarea {
  border: 1px solid #e9e9e9;
  background: #fff;
  padding: 0.618em 1em;
  box-shadow: none;
  min-height: 40px;
  width: 100%;
}

input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus, input:not([type]):focus, textarea:focus,
input[type="color"]:hover, input[type="date"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="email"]:hover, input[type="month"]:hover, input[type="number"]:hover, input[type="password"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="text"]:hover, input[type="time"]:hover, input[type="url"]:hover, input[type="week"]:hover, input:not([type]):hover, textarea:hover {
  border: 1px solid #d0d0d0;
  outline: 0;
  box-shadow: none;
}

input[type="color"]:active, input[type="date"]:active, input[type="datetime"]:active, input[type="datetime-local"]:active, input[type="email"]:active, input[type="month"]:active, input[type="number"]:active, input[type="password"]:active, input[type="search"]:active, input[type="tel"]:active, input[type="text"]:active, input[type="time"]:active, input[type="url"]:active, input[type="week"]:active, input:not([type]):active, textarea:active {
  border: 1px solid #d0d0d0;
  outline: 0;
  box-shadow: none;
}

.inp-rounded {
  border-radius: 30px;
}
